import { Modal, Select, Table } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { OrdersPresentationProps } from "../../domain/configs/ordersViewConfig";
import { IOrder } from "../../../booking/domain/entities/IOrder";
import { TabledBooking, TableViewModel, TabledOrder } from "../view-models/tableViewModel";
import { Observer, observer } from "mobx-react";
import { StyledBookingTable } from "./RestyleTable";
import { EOrderStatus } from "../../../booking/domain/entities/EOrderStatus";
import moment from "moment";
import { OrdersRepository } from "../../../booking/domain/repository/OrdersRepository";
import { container } from "../../../../di/container";
import { DATE_FORMAT } from "../../../../common/constants";
import { OrderService } from "../../../booking/domain/services/OrderService";
import { TimelinePageViewModel } from "../view-models/timelinePageViewModel";
import { ETimelineType } from "../../domain/entities/TimelineType";
import ModalEditOrder from "./ModalEditOrder";
import ConfirmStatusTable from "./ConfirmStatusTable";
import GameTypeStatus from "./GameTypeStatus";
import VipsTableView from "./VipsTableView";

import yesIcon from '../../../../assets/icons/confirmed.svg';
import arrowIcon from '../../../../assets/arrow.svg';
import editIcon from '../../../../assets/edit-2.svg'


type ColType = {
    [k: string]: any,
    dataIndex: (keyof TabledOrder | 'edit' | 'orderPanel' | 'more' | 'number' | 'bookings')
    // dataIndex: (keyof TabledOrder | 'contextMenu')
};

type ExtendedTabledOrder = TabledOrder & {
    isBooking?: boolean,
    gameType?: number,
    orderNum?: number
};

const { Option } = Select;

const { confirm } = Modal;

export const OrdersTable: React.FC<OrdersPresentationProps> = observer(({
    edit,
    orders,
    save,
    cancel,
    isSaving,
    gameTypes
}) => {


    const tableViewModel = useMemo(() => new TableViewModel(), []);

    const ordersRepository = container.get(OrdersRepository);
    const ordersService = container.get(OrderService);

    const isEditing = (record: TabledOrder) => {
        return ordersService.editableOrder?.id === record.id;
        // return tableViewModel.editingOrder?.id == record.id;
    };

    const onSave = useCallback((record: TabledOrder) => {
        const order = ordersRepository.getOrderById(record.id);
        console.log(order);
        save();
        ordersService.setEditableOrder(null);

        setTimeout(() => {
            if (order?.bookings[0].bookingDate) {
                fetchOrders(order?.bookings[0].bookingDate);
            }
        }, 1000);

    }, [save]);

    const handleSave = (updatedOrder: Partial<TabledOrder>) => {

        // save();
        // setIsModalOpen(false);

        const order = ordersRepository.getOrderById(updatedOrder.id!);
        console.log(order);
        save();
        setIsModalOpen(false);
        ordersService.setEditableOrder(null);

        setTimeout(() => {
            if (order?.bookings[0].bookingDate) {
                fetchOrders(order?.bookings[0].bookingDate);
            }
        }, 1000);
    };

    const fetchOrders = async (month: moment.Moment) => {
        const firstDayOfMonth = month.startOf('month').format(DATE_FORMAT);
        const lastDayOfMonth = month.endOf('month').format(DATE_FORMAT);

        await ordersRepository.getOrdersByPeriod(firstDayOfMonth, lastDayOfMonth);

    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        cancel();
        setIsModalOpen(false);
    };

    const editFunc = (order: TabledOrder, edit: (orderStorage: (order: IOrder) => IOrder | null) => void) => {
        console.log(order, edit);

        const onEdit = tableViewModel.buildOnEdit.bind(tableViewModel)(order, edit);

        showModal();

        return onEdit;
    }

    const timelinePresenter = container.get(TimelinePageViewModel);

    const goToTimeline = (record: ExtendedTabledOrder) => {
        const order = ordersRepository.getOrderById(record.id);
        console.log(order?.bookings[0].bookingDate);

        timelinePresenter.loadBookingByDate((order?.bookings[0].bookingDate ?? moment()).format(DATE_FORMAT));
        timelinePresenter.changeSelectedView(ETimelineType.TIMELINE);
    };

    const [expandedRows, setExpandedRows] = useState<string[]>([]);

    const handleExpandRow = (orderId: string) => {
        console.log('click', orderId);

        setExpandedRows((prev) =>
            prev.includes(orderId)
                ? prev.filter((id) => id !== orderId)
                : [...prev, orderId]
        );
    };

    let orderNumber = 0;

    const columns: ColType[] = [
        {
            title: '',
            dataIndex: 'orderPanel',
            width: 10,
            render: (_: any, record: ExtendedTabledOrder) => record.isBooking ? <div className="tdWithoutText" /> : <div className="blueCell" />,
        },
        {
            title: '',
            dataIndex: 'edit',
            width: 40,
            render: (_: any, record: ExtendedTabledOrder) => record.isBooking
                ? <div className="tdWithoutText" />
                : <div
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
                    className="blueCell blueHover"
                    title="Редактирование заказа"
                    onClick={() => editFunc(record, edit)}
                >
                    <img src={editIcon} alt="edit" />
                </div>,
        },
        {
            title: '',
            dataIndex: 'more',
            width: 40,
            render: (_: any, record: ExtendedTabledOrder) => {
                if (record.isBooking) return <div className="tdWithoutText" />
                if (record.bookings.length > 1) return (
                    <div
                        className="centerColumn"
                        onClick={() => handleExpandRow(record.id)}
                        style={{ cursor: 'pointer' }}
                    >
                        {expandedRows.includes(record.id)
                            ? <img style={{ transform: 'rotate(180deg)' }} src={arrowIcon} alt="Collapse" />
                            : <img src={arrowIcon} alt="Expand" />
                        }
                    </div>
                )
                else return <div className="tdWithText" />
            },
        },
        {
            title: <div style={{ textAlign: 'center' }}>№</div>,
            dataIndex: 'number',
            width: 40,
            render: (_: any, record: ExtendedTabledOrder) => record.isBooking ? <div className="tdWithoutText" /> : <div onClick={() => goToTimeline(record)} title="Перейти на этот день" className="centerColumn goTimeline">{record.orderNum}</div>,
        },
        {
            title: 'Администратор',
            dataIndex: 'adminName',
            width: 122,
            render: (_: any, record: ExtendedTabledOrder) => record.isBooking ? <div className='tdWithoutText' /> : <div className="tdWithText">{record.adminName}</div>,
        },
        {
            title: <div className="greenColTitle">Время</div>,
            dataIndex: 'time',
            width: 122,
            render: (_: any, record: ExtendedTabledOrder) => record.time,
            className: 'greenColumn'
        },
        {
            title: <div className="greenColTitle">Комментарий</div>,
            dataIndex: 'comment',
            width: 236,
            render: (_: any, record: ExtendedTabledOrder) => record.comment,
            className: 'greenColumn'
        },
        {
            title: <div className="greenColTitle">СМС</div>,
            dataIndex: 'isMessaged',
            width: 122,
            render: (_: any, record: ExtendedTabledOrder) => record.isMessaged ?
                <div style={{ backgroundColor: 'rgba(100, 180, 120, 1)' }} className='table-item__payment-status' >
                    <img src={yesIcon} alt="sendMessage" />
                    Да
                </div>
                : '',
            className: 'greenColumn'
        },
        {
            title: <div className="greenColTitle">Статус</div>,
            dataIndex: 'status',
            width: 122,
            render: (_: any, record: ExtendedTabledOrder) => <ConfirmStatusTable status={record.status} />,
            className: 'greenColumn'
        },
        {
            title: 'Сумма',
            dataIndex: 'price',
            width: 122,
            render: (_: any, record: ExtendedTabledOrder) => {
                return record.isBooking ? <div className='tdWithoutText' /> : <div className="tdWithText">{record.price ? `${record.price} ₽` : 'Не подсчитано'}</div>
                // if (record.isBooking) return ''
                // else return record.price ? `${record.price} ₽` : 'Не подсчитано'
            },
        },
        {
            title: 'Кол-во гостей',
            dataIndex: 'guestCount',
            width: 118,
            render: (_: any, record: ExtendedTabledOrder) => <div className={record.isBooking ? 'tdWithoutText' : 'tdWithText'}>{record.guestCount}</div>,
        },
        {
            title: 'Бронь',
            dataIndex: 'title',
            width: 118,
            render: (_: any, record: ExtendedTabledOrder) => record.isBooking ? <div className='tdWithoutText' /> : <div className="tdWithText">{record.title}</div>,
        },
        {
            title: 'Игровая зона',
            dataIndex: 'bookings',
            width: 207,
            render: (_: any, record: ExtendedTabledOrder) => {
                if (record.isBooking) {
                    const info = [{
                        type: gameTypes.find(t => t.id == record.gameType)?.label ?? '',
                        time: record.time
                    }];
                    return <div className="tdWithoutText"><GameTypeStatus key={record.id} info={info} /></div>
                }
                else {
                    const typeAndTime = record?.bookings?.map((g, i) => {
                        let label = gameTypes.find(t => t.id == record.gamesTypesId?.[i])?.label ?? '';
                        if (record.gamesTypesId?.[i] == -1) label = 'Таска';
                        return {
                            type: label,
                            time: g.time
                        }
                    })
                    return <div className="tdWithText"><GameTypeStatus key={record.id} info={typeAndTime} /></div>
                }
            },
        },
        {
            title: 'Випки',
            dataIndex: 'vips',
            width: 122,
            render: (_: any, record: ExtendedTabledOrder) => record.isBooking
                ? <div className='tdWithoutText' />
                : <div className="tdWithText"><VipsTableView vip={record.vips ?? []} /></div>,
        },
        {
            title: 'Гость',
            dataIndex: 'clientName',
            width: 115,
            render: (_: any, record: ExtendedTabledOrder) => record.isBooking ? <div className='tdWithoutText' /> : <div className="tdWithText">{record.clientName}</div>,
        },
        {
            title: 'Номер телефона',
            dataIndex: 'phone',
            width: 122,
            render: (_: any, record: ExtendedTabledOrder) => record.isBooking ? <div className='tdWithoutText' /> : <div className="tdWithText">{record.phone}</div>,
        },
        {
            title: 'Именинник',
            dataIndex: 'clientName',
            width: 115,
            render: (_: any, record: ExtendedTabledOrder) => record.isBooking ? <div className='tdWithoutText' /> : <div className="tdWithText">{record.clientName}</div>,
        },
    ]



    useEffect(() => tableViewModel.updateOrders(orders ?? []), [orders, ordersRepository.ordersPeriod]);

    const sortedDataSource = tableViewModel.tOrders
        .map(tO => {
            const bookings = tableViewModel.tBookings.find(tbookings => tbookings.find(b => b.orderId === tO.id)) ?? [];
            return {
                ...tO,
                bookings,
            };
        })
        .sort((a, b) => {
            if (a.status === EOrderStatus.REJECTED && b.status !== EOrderStatus.REJECTED) {
                return -1;
            } else if (a.status !== EOrderStatus.REJECTED && b.status === EOrderStatus.REJECTED) {
                return 1;
            } else {
                return moment(a.time, 'HH:mm').diff(moment(b.time, 'HH:mm'));
            }
        });

    const expandedDataSource = sortedDataSource.flatMap(order => {
        // const rows = [order];
        let orderNum = orderNumber + 1;
        orderNumber++;
        const rows = [
            { ...order, orderNum },
        ];

        if (expandedRows.includes(order.id)) {
            const bookingsWithGameTypes = order.bookings.map((booking, index) => ({
                ...booking,
                gameType: order.gamesTypesId?.[index] ?? null,
            }));

            const sortedBookings = bookingsWithGameTypes.sort((a, b) =>
                moment(a.time, 'HH:mm').diff(moment(b.time, 'HH:mm'))
            );

            const bookingRows = sortedBookings.map((booking, index) => ({
                ...order,
                ...booking,
                orderNum,
                isMessaged: false,
                status: 0,
                clientName: '',
                phone: '',
                vips: [],
                comment: '',
                adminName: '',
                price: 0,
                title: '',
                isBooking: true,
                key: `${order.id}-booking-${index}`,
                guestCount: booking.guestCount.toString(),
                gameTypeLabel: booking.gameType === -1
                    ? 'Таска'
                    : gameTypes.find(type => type.id === booking.gameType)?.label ?? '',
            }));

            rows.push(...bookingRows);
        }

        return rows;
    });

    return (
        <>
            <StyledBookingTable
                bordered
                showSorterTooltip={false}
                dataSource={expandedDataSource}
                columns={columns}
                tableLayout='fixed'
                pagination={false}
                locale={{
                    emptyText: <div className="emptyTable">Броней нет</div>
                }}
            />
            <ModalEditOrder
                handleCancel={handleCancel}
                isModalOpen={isModalOpen}
                handleSave={handleSave}
            />
        </>
    );
});