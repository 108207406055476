import React, { useMemo } from 'react';
import { EOrderStatus } from '../../../booking/domain/entities/EOrderStatus';
import { EBookingStatus } from '../../../booking/domain/entities/EBookingStatus';

import processing from '../../../../assets/icons/processing.svg'
import rejected from '../../../../assets/icons/rejected.svg'
import confirmed from '../../../../assets/icons/confirmed.svg'

const ConfirmStatusTable: React.FC<{ status: EBookingStatus | EOrderStatus }> = ({ status }) => {


    const statusStyle = useMemo((): React.CSSProperties => {
        switch (status) {
            case EBookingStatus.CONFIRMED | EOrderStatus.CONFIRMED:
                return {
                    backgroundColor: 'rgba(100, 180, 120, 1)',
                };
            case EBookingStatus.REJECTED | EOrderStatus.REJECTED:
                return {
                    backgroundColor: 'rgba(223, 92, 92, 1)',
                };
            case EBookingStatus.CREATED | EOrderStatus.CREATED:
                return {
                    display: 'none'
                };
            case EBookingStatus.PROCESSING | EOrderStatus.PROCESSING:
                return {
                    backgroundColor: 'rgba(61, 131, 237, 1)',
                };
            default: {
                return {
                    backgroundColor: '#F0F8FE',
                    border: '1px solid #2F80ED',
                    color: '#2F80ED'
                };
            }
        }
    }, [status]);

    const statusText = useMemo((): string => {
        switch (status) {
            case EBookingStatus.CONFIRMED:
                return 'Подтв.';
            case EBookingStatus.REJECTED:
                return 'Отмена';
            case EBookingStatus.PROCESSING:
                return 'Предвар.';
            default:
                return '';
        }
    }, [status]);

    const statusIcon = useMemo((): string => {
        switch (status) {
            case EBookingStatus.CONFIRMED:
                return confirmed;
            case EBookingStatus.REJECTED:
                return rejected;
            case EBookingStatus.PROCESSING:
                return processing;
            default:
                return '';
        }
    }, [status]);

    return (
        <div style={statusStyle} className='table-item__payment-status' >
            <img src={statusIcon} alt="icon" />
            {statusText}
        </div>
    );
};

export default ConfirmStatusTable;