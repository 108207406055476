import ColorPool from "../../../../common/utility-types/utils/ColorPool";
import { GameType } from "../../domain/entities/GameType";

type GameTypeViewProps = { gameType: GameType }

const arrColor: Record<number, string> = {
    1: '#64b478',
    2: '#6c80cd',
    4: '#af76b4',
    5: '#e79664',
    6: '#72d4d4',
    10: '#df5c5c',
    11: '#df5c5c'
}

export const GameTypeView: React.FC<GameTypeViewProps> = ({ gameType }) => {
    return (
        <div key={gameType.id} className="game-type-container gane-type" style={{ border: `1px solid ${arrColor[gameType.id]}`, backgroundColor: `${arrColor[gameType.id]}1a`, width: gameType.id == 4 ? 220 : 150 }}>
            <label
                className="game-type__color"
                // style={{ backgroundColor: ColorPool.instance.getColor(gameType.id) }} />
                style={{ backgroundColor: arrColor[gameType.id] }} />
            {gameType.label}
        </div>
    );
}
// style={{ backgroundColor: arrColor[gameType.id] }} />
// style={{ border: `1px solid ${arrColor[gameType.id]}`, backgroundColor: `${arrColor[gameType.id]}1a` }}