import { useGesture } from '@use-gesture/react';
import { Col, Row, message } from 'antd';
import React, { useRef } from 'react';
import { Control, UseFormSetValue, UseFormWatch, useFieldArray, useWatch } from 'react-hook-form';
//import { availableGlassesSelector } from '../../timeline/redux/selectors';

import BookingForm from './BookingForm';
// import { DATE_PICKER_PATH, BOOKING_LIST_PATH, FormBooking, TIME_PATH, GUEST_COUNT_PATH, OrderFormState } from '../OrderCreateForm';
import { DateGipno } from '../../../../common/presentation/gipno-ui-kit/date';
import { IHookFormBooking, IHookFormOrder } from '../../domain/entities/IHokFormOrder';
import ColorPool from '../../../../common/utility-types/utils/ColorPool';
import DefaultController from '../../../../common/presentation/DefaultController';
import { ValidatorHelper } from '../../../../common/utility-types/helpers/ValidatorHelper';

interface BookingGroupProps {
    bookings: IHookFormBooking[],
    orderId?: number | string,
    setValue: UseFormSetValue<IHookFormOrder>,
    control: Control<IHookFormOrder, any>
}

const BookingGroup: React.FC<BookingGroupProps> = ({ orderId, bookings, setValue, control }) => {

    //const colorBuilder =  //useSelector(timelineSelector).timeline.config.colorBuilder;

    const bookingsContainerRef = useRef<HTMLDivElement>(null);

    const bind = useGesture({
        onDrag: ({ delta }) => {
            bookingsContainerRef.current?.scrollBy(-delta[0], 0);
            document.body.style.cursor = 'grabbing';
            document.body.style.userSelect = 'none';
        },
        onDragEnd: () => {
            document.body.style.cursor = 'grab';
            document.body.style.userSelect = 'auto';
        },
        onHover: ({ hovering }) => {
            if (hovering) {
                document.body.style.cursor = 'grab';
            }
            else {
                document.body.style.cursor = 'default';
            }
        },
    })


    const buildOnRemove = (id: number | string) => () => setValue('bookings', bookings?.filter(booking => booking.id != id) ?? []);

    const arrColor: Record<number, string> = {
        1: '#64b478',
        2: '#6c80cd',
        4: '#af76b4',
        5: '#e79664',
        6: '#72d4d4',
        10: '#df5c5c',
        11: '#df5c5c'
    }

    return (
        <>
            <div {...bind()} ref={bookingsContainerRef} className="bookings-wrapper">
                <DefaultController
                    control={control}
                    name='bookings'
                    warn={[
                        {
                            validator: ValidatorHelper.bookingsTimeValidator,
                            message: 'Образовалась временная яма'
                        }
                    ]}
                    onWarn={(warn) => message.warning(warn)}
                    render={({ field }) => {
                        console.log(field.value);
                        
                        return (
                            <Row gutter={[20, 0]} wrap={false}>
                                {field.value && field.value.map((booking, index) => (
                                    <Col key={booking.id} >
                                        <BookingForm
                                            remove={buildOnRemove(booking.id)}
                                            key={booking.id}
                                            bookings={bookings ?? []}
                                            orderId={orderId ?? -1}
                                            index={index}
                                            color={arrColor[booking.gameTypeId ?? -1]}
                                        />
                                    </Col>
                                ))}

                            </Row>
                        )
                    }} />
            </div>
        </>
    );
}

export default BookingGroup;
