import React, { memo, Suspense, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Without } from "../../../../@types/without";
import { ITimelineSettings } from "../../domain/entities/ITimelineSettings";
import { IOrder } from "../../../booking/domain/entities/IOrder";
import { ITimelineParams } from "../../domain/entities/TimelineParams";
import { Observer, observer } from "mobx-react-lite";
import { GipnoTable, TColumnType, TRowType } from "../../../../common/presentation/enhasted-table";
import { IBookingCell } from "../../domain/entities/IBookingCell";
import Cell from "./Cell";
import { DashboardViewModel } from "../view-models/dashboardViewModel";
import { DashboardOrderAdapter } from "../../domain/utils/DashboardOrderAdapter";
import { ITimeline } from "../../domain/entities/ITimeline";
import { TCellData, TRow } from "../../../../common/presentation/enhasted-table/types";
import moment from "moment";
import { TimelineInfo } from "../../../../api/models/OrderForDayResponse";
import { OrdersPresentationProps } from "../../domain/configs/ordersViewConfig";
import { Timeline } from "../../domain/entities/Timeline";
import useContextMenu, { BookingContext } from "../../../booking/domain/helpers/ContextMenu";
import { createPortal } from "react-dom";
import { animated, useSpring, useSprings } from "react-spring";
import { container } from "../../../../di/container";
import { BookingCell } from "../../domain/entities/BookingCell";
import { Vector2 } from "@use-gesture/react";
import { OrdersRepository } from "../../../booking/domain/repository/OrdersRepository";
import { TimelineColumn } from "../../domain/entities/TimelineColumn";
import { LoungeZoneList } from "../../../lounge-zones/presentation/LoungeZoneList";

import { IBooking } from "../../../booking/domain/types";

interface INeighbors {
    top?: IBookingCell;
    bottom?: IBookingCell;
    left?: IBookingCell;
    right?: IBookingCell;
}

type Row = {
    rKey: string;
    data: TCellData<IBookingCell | IBookingCell[] | null>[];
};


export const Dashboard: React.FC<OrdersPresentationProps> = observer(({
    orders,
    infos,
    settings,
    isLoading,
    showCanceled,
    edit,
    save
}) => {

    const dashboardViewModel = useMemo(() => container.get(DashboardViewModel), []);
    // const [currentGlassesCount, setCurrentGlassesCount] = useState(settings.glassesCount);

    useEffect(() => {
        dashboardViewModel.updateIsLoading(isLoading);
    }, [isLoading]);

    useEffect(
        () => {
            if (orders && infos) dashboardViewModel.adaptToTimeline(orders, infos);
        }, [orders, infos]
    );

    useEffect(
        () => {
            // Придумал Артем: аналитически подобранный коэфициент для коректного отображения в транспонированном виде
            const x = settings.isTransposed ? 10 : 0;
            getComputedStyle(document.documentElement)
                .getPropertyValue('--column-count');

            document.documentElement.style
                .setProperty('--column-count', `${dashboardViewModel.tCols.length + x}`);
        },
        [dashboardViewModel.tCols, settings.isTransposed]
    );



    const dashboardVM = container.get(DashboardViewModel);

    const orderRepo = container.get(OrdersRepository);
    useEffect(() => {
        if (orders && infos) {
            dashboardViewModel.adaptToTimeline(orders, infos);
        }
        if (settings.glassesCount !== undefined) {
            // console.log(settings.glassesCount);

            dashboardViewModel.updateGlassesCount(settings.glassesCount);
        }
        if (settings.rowsCount !== undefined) {
            // console.log(settings.rowsCount);

            dashboardViewModel.updateRowsCount(settings.rowsCount);
        }
    }, [orders, infos, settings.glassesCount, settings.rowsCount]);


    const onCellDrag = (from: TCellData<IBookingCell | IBookingCell[] | null> | null, to: TCellData<IBookingCell | IBookingCell[] | null> | null) => {
        if (from?.data && to?.data && !Array.isArray(from.data) && !Array.isArray(to.data)) {

            if (dashboardViewModel.selectedBookings.length === 0) {
                dashboardViewModel.onDragCell(from.data, to.data, edit);
            }

            // console.log(from.data?.booking?.id);
            // console.log(from.data);
            // console.log(from.data?.id.split('/')[0]);


            const orderByRepo = orderRepo.getOrdersByDate(from.data.id.split('/')[0])[0] as IOrder;
            // console.log(dashboardViewModel.selectedBookings);
            // console.log(orderByRepo);
            // console.log(from);
            // console.log(to);

            const fromCellId = from.id.split('/');
            const toCellId = to.id.split('/');

            const toTime = moment(toCellId[0]);
            const fromTime = moment(fromCellId[0]);


            const differenceInDays = toTime.diff(fromTime, 'days');
            const differenceInHours = toTime.diff(fromTime, 'hours');

            // const baseDate = moment("2024-06-03T12:00:00+04:00");
            // const newDate = baseDate.add(differenceInHours, 'hours').add(differenceInDays, 'days').format();

            // console.log('differenceInHours ' + differenceInHours, 'differenceInDays ' + differenceInDays);

            const deltaRow = +toCellId[1] - +fromCellId[1];

            dashboardViewModel.selectedBookings.forEach(selectedBook => {

                // console.log('---------------------------------------');
                // console.log(selectedBook);

                // console.log(differenceInHours);
                // console.log(differenceInDays);
                // console.log('delta', deltaRow);

                const cellIdFrom = `${selectedBook.bookingDate?.format()}/${selectedBook.rowCellId}`;
                // console.log(cellIdFrom);


                const cellFrom = dashboardVM.timeline.getCellById(cellIdFrom);

                const newDate = selectedBook.bookingDate?.add(differenceInHours, 'hours').add(differenceInDays, 'days').format();


                const cellIdTo = `${newDate}/${+selectedBook.rowCellId + deltaRow}`;
                // console.log(cellIdTo);


                const cellTo = dashboardVM.timeline.getCellById(cellIdTo);


                if (cellFrom && cellTo) {
                    dashboardViewModel.onDragCell(cellFrom, cellTo, edit);

                    dashboardVM.setClearSelectedBookings();
                    // console.log(dashboardVM.selectedBookings);

                }

            });

        }

        // api.stop(true);
    };

    const onDragging = (
        from: TCellData<IBookingCell | IBookingCell[] | null> | null,
        to: TCellData<IBookingCell | IBookingCell[] | null> | null,
        x: number | null,
        y: number | null
    ) => {
        if (to?.data && from?.data) {
            const cell = (to.data as IBookingCell);
            const sCell = (from.data as IBookingCell);
            dashboardViewModel.onDragging(sCell.id, to.id, cell.time, Number.parseInt(cell.additionalId.toString()));
        }


        // api.start(movementAnimFn(
        //     (x ?? 0) + startVector.current[0],
        //     (y ?? 0) + startVector.current[1] 
        // ));
        // if (from && to) {

        // console.log('FROM ---', (from.data as IBookingCell).time);
        // console.log('TO -----', (to.data as IBookingCell).time);
        // }
    };

    const { isShown, menu, hideContextMenu, showContextMenu } = useContextMenu();

    const cellsBorder = useMemo(() => {
        if (!orders) return [];

        const bordersToRemove: { cellId: string, border: 'top' | 'bottom' | 'left' | 'right' }[] = [];

        orders.forEach(order => {
            const bookings = order.bookings;

            for (let i = 0; i < bookings.length; i++) {
                const currentBooking = bookings[i];
                const currentCellId = `${currentBooking.bookingDate?.format()}/${currentBooking.rowCellId}`;

                if (settings.isTransposed) {
                    const cellAbove = bookings.find(b => b.rowCellId === currentBooking.rowCellId && b.bookingDate?.diff(currentBooking.bookingDate, 'hours') === -1);
                    if (cellAbove) {
                        bordersToRemove.push({ cellId: currentCellId, border: 'top' });
                    }

                    const cellBelow = bookings.find(b => b.rowCellId === currentBooking.rowCellId && b.bookingDate?.diff(currentBooking.bookingDate, 'hours') === 1);
                    if (cellBelow) {
                        bordersToRemove.push({ cellId: currentCellId, border: 'bottom' });
                    }

                    const cellLeft = bookings.find(b => b.bookingDate?.format() === currentBooking.bookingDate?.format() && b.rowCellId === currentBooking.rowCellId - 1);
                    if (cellLeft) {
                        bordersToRemove.push({ cellId: currentCellId, border: 'left' });
                    }

                    const cellRight = bookings.find(b => b.bookingDate?.format() === currentBooking.bookingDate?.format() && b.rowCellId === currentBooking.rowCellId + 1);
                    if (cellRight) {
                        bordersToRemove.push({ cellId: currentCellId, border: 'right' });
                    }
                } else {
                    const cellAbove = bookings.find(b => b.bookingDate?.format() === currentBooking.bookingDate?.format() && b.rowCellId === currentBooking.rowCellId - 1);
                    if (cellAbove) {
                        bordersToRemove.push({ cellId: currentCellId, border: 'top' });
                    }

                    const cellBelow = bookings.find(b => b.bookingDate?.format() === currentBooking.bookingDate?.format() && b.rowCellId === currentBooking.rowCellId + 1);
                    if (cellBelow) {
                        bordersToRemove.push({ cellId: currentCellId, border: 'bottom' });
                    }

                    const cellLeft = bookings.find(b => b.rowCellId === currentBooking.rowCellId && b.bookingDate?.diff(currentBooking.bookingDate, 'hours') === -1);
                    if (cellLeft) {
                        bordersToRemove.push({ cellId: currentCellId, border: 'left' });
                    }

                    const cellRight = bookings.find(b => b.rowCellId === currentBooking.rowCellId && b.bookingDate?.diff(currentBooking.bookingDate, 'hours') === 1);
                    if (cellRight) {
                        bordersToRemove.push({ cellId: currentCellId, border: 'right' });
                    }
                }
            }
        });

        return bordersToRemove;
    }, [orders, settings.isTransposed]);
    // console.log(cellsBorder);


    const addBorderClass = (cellId: string, border: 'top' | 'bottom' | 'left' | 'right') => {
        let className = '';
        switch (border) {
            case 'top':
                className = 'no-border-top';
                break;
            case 'bottom':
                className = 'no-border-bottom';
                break;
            case 'left':
                className = 'no-border-left';
                break;
            case 'right':
                className = 'no-border-right';
                break;
        }
        // console.log(`Applying border class ${className} to cell ${cellId}`);
        return className;
    };

    interface GroupedBorders {
        [key: string]: string[];
    }

    const groupedBorders: GroupedBorders = cellsBorder.reduce((acc, { cellId, border }) => {
        if (!acc[cellId]) {
            acc[cellId] = [];
        }
        acc[cellId].push(addBorderClass(cellId, border));
        return acc;
    }, {} as GroupedBorders);


    const [rows, setRows] = useState<{
        rKey: string;
        data: TCellData<IBookingCell | IBookingCell[] | null>[];
    }[]>(dashboardViewModel.tRows);

    useEffect(() => {
        // if (settings.showCanceled) {
        // const updatedRows = dashboardViewModel.tRows.map(row => {
        //     return ({
        //         ...row,
        //         data: row.data.map(elem => {
        //             const bookingCell = elem.data as IBookingCell;
        //             return {
        //                 ...elem,
        //                 data: bookingCell.booking?.status !== 3
        //                     ? { ...bookingCell, booking: null }
        //                     : bookingCell
        //             };
        //         })
        //     })
        // });
        // setRows(updatedRows);
        // } else {
        const updatedRows = dashboardViewModel.tRows.map(row => {
            return ({
                ...row,
                data: row.data.map(elem => {
                    const bookingCell = elem.data as IBookingCell;
                    return {
                        ...elem,
                        data: bookingCell.booking?.status === 3
                            ? { ...bookingCell, booking: null }
                            : bookingCell
                    };
                })
            })
        });
        setRows(updatedRows);
        // }

    }, [dashboardViewModel.tRows]);

    return (
        <BookingContext.Provider value={{ isShown, menu, hideContextMenu, showContextMenu }}>
            {/* <div style={{ position: 'relative' }}> */}
            <GipnoTable
                key={settings.isTransposed ? "transposed" : "normal"}
                headerStyleClass={'headerStyle'}
                width={'expand'}
                height={'expand'}
                isFixed={settings.isFixed}
                transposition={settings.isTransposed}
                columns={dashboardViewModel.tCols}
                subheader={(col) => {
                    if (!col || !col.cKey) return null;
                    
                    let loungeZones = [
                        { id: '1', title: '1', isOccupied: true },
                        { id: '2', title: '2', isOccupied: true },
                        { id: '3', title: '3', isOccupied: true }
                    ];
                    infos?.forEach((info, index) => {
                        if (col.cKey == (info.time + '+04:00')) {
                            info.info?.availableRooms?.forEach(room => {
                                let zone = loungeZones.find(zone => +zone.id == room.id);
                                if (zone) {
                                    zone.isOccupied = false;
                                }
                            });
                        }
                    });
                    return (<LoungeZoneList isFixed={settings.isFixed} isTransposed={settings.isTransposed} loungeZones={loungeZones} />);
                }}
                onCellClick={(cell => {
                    hideContextMenu();
                    return dashboardViewModel.onCellClick(cell.data as IBookingCell, edit);
                })}
                onDragging={onDragging}
                isHeadFixed={true}
                cellDragTimeDelay={300}
                headerBuilder={col => {
                    if (!col || !col.cKey) return null;
                    const remainingGlasses = dashboardViewModel.timeline?.columnsMap.get(moment(col.cKey).format(moment.defaultFormat))?.remainingGlasses;
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {`${col.label}/${remainingGlasses ?? 'Неизвестно'}`}
                        </div>
                    );
                }}
                onCellDrag={onCellDrag}
                // rows={dashboardViewModel.tRows}
                rows={rows}
                cellBuilder={(tCell) => {

                    const fullOrder = dashboardViewModel.getOrderById(((tCell as IBookingCell).booking?.orderId ?? ''));

                    return (
                        <Observer >
                            {() =>
                                <Cell
                                    borderClasses={
                                        cellsBorder.map(border => {
                                            if (!Array.isArray(tCell)) {
                                                if (border.cellId === tCell?.id) {
                                                    return addBorderClass(tCell.id, border.border);
                                                }
                                            }
                                            return '';
                                        }).join(' ')
                                    }
                                    {...tCell as IBookingCell}
                                    orderTitle={fullOrder?.title ?? null}
                                    key={(tCell as IBookingCell).id}
                                    isEditing={(tCell as IBookingCell).isEditing}
                                    cellStyle={settings.cellStyle}
                                />
                            }</Observer>
                    );
                }

                }
            />
            {/* </div> */}
        </BookingContext.Provider >
    );
});