import React, { useState, useEffect } from "react";

import logo from '../../../assets/Logotype.svg'

const LoadingScreen: React.FC = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoaded(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    if (isLoaded) {
        return <div>Основной контент приложения</div>;
    }

    return (
        <div className="loading-screen">
            <img src={logo} alt="Логотип" className="loading-logo" />
            <div className="loading-progress-bar-container">
                <div className="loading-progress-bar"></div>
            </div>
        </div>
    );
};

export default LoadingScreen;
