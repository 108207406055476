import React from "react";
import { ConnectOrder } from "../providers/OrderProvider";
import DefaultController from "../../../../common/presentation/DefaultController";
import { ControllerRenderProps, ControllerFieldState, UseFormStateReturn } from "react-hook-form";
import { IHookFormOrder } from "../../domain/entities/IHokFormOrder";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";

export const CommentController: React.FC = () => {
    return <ConnectOrder >
        {({ control }) => <DefaultController
            control={control}
            name='comment'
            render={({ field }) => <StyledTextArea
                {...field}
                placeholder="Комментарий"
                value={field.value ?? ''}
                showCount
                style={{ height: 40 }}
            />}
        />}
    </ConnectOrder>
}

const StyledTextArea = styled(TextArea)`
    
    textarea {
        border-radius: 15px;
        background-color: #F5F6F8;
        border: none;
        min-height: 100px;
    }
`