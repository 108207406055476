import { Col, Row, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import '../features/authentication/ui/AdminLoginStyles.css';
import { LoginViewModal } from '../features/authentication/ui/loginViewModal';
import { useNavigate } from 'react-router';
import { FilialViewModal } from '../features/fillial/ui/view-models/FilialViewModel';
import { ADMIN_TIMELINE_PATH } from '../common/router/RouterConfiguration';
import Loader from '../common/presentation/Loader';
import { RequestStatus } from '../@types/requestStatus';
import { observer } from 'mobx-react';

import logo from '../assets/Logotype.svg'
import arrow from '../assets/arrow.svg'


export const CitySelectPage: React.FC = observer(() => {

    const navigate = useNavigate();

    // const [selectedCity, setAvailableCities] = useState<ICity>();
    const [selectedCity, setSelectedCity] = useState<string>();
    const [error, setError] = useState<string | null>(null);

    // const filialVM = new FilialViewModal();
    const filialVM = useMemo(() => new FilialViewModal(), []);

    // const availableCities = auth.credential.availableCities;
    const availableCities = filialVM.credentialData.availableCities;
    const user = filialVM.credentialData.user;

    useEffect(() => {
        if (user) {
            filialVM.availableCities(user.id);
        }
    }, [user]);

    const confirm = () => {
        if (!selectedCity) {
            setError('Выберите город');
            return;
        }

        const newCity = availableCities.filter(c => c.name === selectedCity)[0];
        filialVM.filialData.setCity(newCity);

        localStorage.setItem('filial', JSON.stringify(newCity));
        navigate(ADMIN_TIMELINE_PATH);
    };

    const auth = new LoginViewModal();

    const logout = () => {
        auth.logout();
        navigate(-1);
    };

    const onSelect = (v: string, opt: {label: string, value: string}) => {
        setSelectedCity(opt.label);
        setError(null);
    }

    const isUnavailable = useMemo(() => availableCities.length === 0 || availableCities.at(0) == null, [availableCities]);
    //  sm={24} md={20} lg={18} xl={16}
    return (
        <Row className='white-bg' align='middle' justify='center'>
            <Col xs={23} sm={17} md={13} lg={11} xl={8} xxl={7} className='modalBg'>
                <img className='modalLogo' src={logo} alt="logo" />
                <h2 className="loginTitle">Выберите филиал</h2>
                <h3>Выберите филиал, в котором вы находитесь</h3>
                <Col style={{ width: '100%' }}>
                    {/* {error &&
                        <Col xs={24} sm={20} md={16} lg={14}>
                            <p className='error-message'>{error}</p>
                        </Col>
                    } */}

                    <Col /*xs={24} sm={20} md={16} lg={14} className='city-select-list'*/>
                        {
                            filialVM.citiesStatus === RequestStatus.LOADING ?
                                <Loader color={'#4474e9'} size={32} />
                                :

                                isUnavailable
                                    ?
                                    <div className='no-cities-tip'>нет доступных городов</div>
                                    :
                                    <>
                                        <p className='error-message'>{error}</p>
                                        <Select
                                            placeholder='Выберите филиал'
                                            options={availableCities.map(city => ({
                                                label: city.name,
                                                value: city.code 
                                            }))}
                                            suffixIcon={<img src={arrow} alt='allow' />}
                                            className='city-select'
                                            popupClassName='custom-dropdown'
                                            onSelect={onSelect}
                                        />
                                    </>
                            // availableCities.map(city => {
                            //     return (
                            //         <div
                            //             className={`city-selectable${city.name === selectedCity ? ' city-selected' : ''}`}
                            //             key={city.code}
                            //             onClick={() => setSelectedCity(city.name)}
                            //         >
                            //             {city.name}
                            //         </div>);
                            // })
                        }
                    </Col>

                    <Row gutter={16} justify='center' style={{ marginTop: '16px' }}>
                        <Col span={12}>
                            <button
                                className="back-btn"
                                onClick={logout}
                            >
                                Назад
                            </button>
                        </Col>
                        <Col span={12}>
                            <button
                                className="btn-block login-button signin"
                                onClick={confirm}
                            >
                                Выбрать
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Col>
        </Row>
    );
});

