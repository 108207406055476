import { Col, message, Modal, Radio, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { TabledOrder } from '../view-models/tableViewModel';
import { EOrderStatus } from '../../../booking/domain/entities/EOrderStatus';
import { container } from '../../../../di/container';
import { OrderService } from '../../../booking/domain/services/OrderService';
import { ConnectOrder } from '../../../booking/ui/providers/OrderProvider';
import DefaultController from '../../../../common/presentation/DefaultController';
import { CommentController } from '../../../booking/ui/components/CommentController';
import { EmployeeCodeInput } from '../../../booking/ui/components/EmployeeCodeInput';
import { OrderRemoteDataSource } from '../../../../api/services/OrderService';
import { EmployeeNameInput } from '../../../booking/ui/components/EmployeeNameInput';
import arrow from '../../../../assets/arrow-right.svg';
import styled from 'styled-components';

import './modal.css'

type ModalEditOrderProps = {
    isModalOpen: boolean,
    handleCancel: () => void,
    handleSave: (updatedOrder: Partial<TabledOrder>) => void,
}

const ModalEditOrder: React.FC<ModalEditOrderProps> = ({ handleCancel, isModalOpen, handleSave }) => {


    const ordersService = container.get(OrderService);
    const order = ordersService.editableOrder;
    
    const [status, setStatus] = useState(order?.status ?? '');
    const [comment, setComment] = useState(order?.comment ?? '');
    const [employeeName, setEmployeeName] = useState<string | null>(null);

    if (order) console.log(order);

    useEffect(() => {
        setStatus(order?.status ?? '');
        setComment(order?.comment ?? '');
    }, [order]);

    const [code, setCode] = useState<null | number>(null);

    const sendCode = async () => {
        if (!code) return;
        try {
            const response = await OrderRemoteDataSource.getPersonalByCode(code);
            if (response.id) {
                setEmployeeName(response.name ?? null);
            }
        } catch (error) {
            message.error("Сотрудник не найден");
            setEmployeeName('0');
        }
    }

    const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (!value) {
            setEmployeeName('0');
            return;
        }
        setCode(+value);

        try {
            const response = await OrderRemoteDataSource.getPersonalByCode(parseInt(value));
            if (response.id) {
                setEmployeeName(response.name ?? null);
            }
        } catch (error) {
            message.error("Сотрудник не найден");
            setEmployeeName('0');
        }
    };

    const handleSaveClick = () => {
        if (!code) return

        const updatedOrder: Partial<TabledOrder> = {
            ...order,
            title: order?.title ?? '',
            status: status as EOrderStatus,
            adminName: employeeName!,
            comment,
        };
        console.log(updatedOrder);

        handleSave(updatedOrder);
    };


    return (
        <Modal
            title="Редактирование заказа"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            maskClosable={false}
            className='modalEdit'
        >
            <div>
                <label style={{ marginBottom: '10px' }} >Статус заказа</label>
                <ConnectOrder>
                    {({ control }) => (
                        <DefaultController
                            control={control}
                            name="status"
                            render={({ field }) => (
                                <Radio.Group
                                    style={{ width: '100%' }}
                                    value={field.value}
                                    onChange={(e) => field.onChange(e.target.value)}
                                >
                                    <Row gutter={[9, 9]} className='modalRowStatus'>
                                        <Col span={8}>
                                            <Radio.Button value={EOrderStatus.CONFIRMED}>Подтверждено</Radio.Button>
                                        </Col>
                                        <Col span={8}>
                                            <Radio.Button value={EOrderStatus.REJECTED}>Отменено</Radio.Button>
                                        </Col>
                                        <Col className='modalColStatus' span={8}>
                                            <Radio.Button value={EOrderStatus.PROCESSING}>Предварительно</Radio.Button>
                                        </Col>
                                    </Row>
                                </Radio.Group>
                            )}
                        />
                    )}
                </ConnectOrder>


                <label style={{ margin: '20px 0 10px' }} >Отметка об СМС</label>
                <ConnectOrder>
                    {({ control }) => (
                        <DefaultController
                            control={control}
                            name="isSendMessage"
                            render={({ field }) => (
                                <Radio.Group
                                    style={{ width: '100%' }}
                                    value={field.value}
                                    onChange={(e) => field.onChange(e.target.value)}
                                >
                                    <Row gutter={[20, 10]}>
                                        <Col span={12}>
                                            <Radio.Button className={`${field.value == true ? 'yesActive' : ""}`} value={true}>Да</Radio.Button>
                                        </Col>
                                        <Col span={12}>
                                            <Radio.Button className={`${field.value == false ? 'noActive' : ""}`} value={false}>Нет</Radio.Button>
                                        </Col>
                                    </Row>
                                </Radio.Group>
                            )}
                        />
                    )}
                </ConnectOrder>



                <label style={{ margin: '20px 0 10px' }} >Комментарий</label>
                <CommentController />


                <div className='modalCode'>
                    <label>Код сотрудника</label>
                    <div className='modalCodeSave'>
                        <div className='modalCodeGrid'>
                            <div className='modalCodeInput'>
                                <div style={{ width: '100%' }}>
                                    <EmployeeCodeInput handleBlur={handleBlur} />
                                </div>
                                <img onClick={sendCode} style={{ height: '40px', borderRadius: "15px", width: '20px', cursor: 'pointer' }} src={arrow} alt="arrow" />
                            </div>
                            <ConnectOrder >
                                {(orderApi) => <EmployeeNameInput {...orderApi} value={employeeName ?? '1'} />}
                            </ConnectOrder >
                        </div>
                        <StyledButtonSave onClick={handleSaveClick}>Сохранить</StyledButtonSave>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalEditOrder


const StyledButtonSave = styled.button`
    width: 30%;
    background: #444656;
    border-radius: 16px;
    color: white;
    font-size: 14px;
`