import { LoginModal } from '../features/authentication/ui/LoginModal';
import { Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { LoginViewModal } from '../features/authentication/ui/loginViewModal';
import Loader from '../common/presentation/Loader';
import LoadingScreen from '../features/authentication/ui/LoadingScreen';

import 'bootstrap/dist/css/bootstrap.css';
import '../features/authentication/ui/AdminLoginStyles.css';

let isTokenLoginRequested = false;

export const AuthPage: React.FC = () => {
    const path = window.location.pathname;

    const auth = useMemo(() => new LoginViewModal(), []);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);


    return (
        <>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <Row className='white-bg' align='middle' justify='center'>
                    <LoginModal />
                </Row>
            )}
        </>

    );
};
