import React, { useMemo } from 'react';
import { EOrderStatus } from '../../../booking/domain/entities/EOrderStatus';
import { EBookingStatus } from '../../../booking/domain/entities/EBookingStatus';

import classic from '../../../../assets/icons/classic.svg'
import quests from '../../../../assets/icons/colonired.svg'
import pp from '../../../../assets/icons/attractions.svg'
import cube from '../../../../assets/icons/cube.svg'
import multiarena from '../../../../assets/icons/gun.svg'
import others from '../../../../assets/icons/inPlace.svg'
import task from '../../../../assets/edit-2.svg'

const GameTypeStatus: React.FC<{ info: { type: string, time: string }[] }> = ({ info }) => {

    info.sort((a, b) => +a.time.slice(0, 2) - +b.time.slice(0, 2));

    const getTypeStyle = (type: string): React.CSSProperties => {
        switch (type) {
            case 'Классика':
                return { backgroundColor: 'rgba(100, 180, 120, 1)' };
            case 'Квесты':
                return { backgroundColor: 'rgba(108, 128, 205, 1)' };
            case 'Полное погружение':
                return { backgroundColor: 'rgba(175, 118, 180, 1)' };
            case 'Куб':
                return { backgroundColor: 'rgba(231, 150, 100, 1)' };
            case 'Мультиарена':
                return { backgroundColor: 'rgba(114, 212, 212, 1)' };
            case 'Таска':
                return { backgroundColor: 'rgba(28, 28, 26, 0.6)' };
            default:
                return { backgroundColor: 'rgba(223, 92, 92, 1)' };
        }
    };

    const getTypeIcon = (type: string): string => {
        switch (type) {
            case 'Классика':
                return classic;
            case 'Квесты':
                return quests;
            case 'Полное погружение':
                return pp;
            case 'Куб':
                return cube;
            case 'Мультиарена':
                return multiarena;
            case 'Таска':
                return task;
            default:
                return others;
        }
    };

    return (
        <>
            {info.length == 1 &&
                <div style={getTypeStyle(info[0].type)} className='table-item__payment-type' >
                    <img style={{width: 15}} src={getTypeIcon(info[0].type)} alt="icon" />
                    {info[0].type}
                </div>
            }
            {info.length !== 1 &&
                <div style={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    {info.map((i, index) =>
                        <div key={i.time+i.type+index} style={{ ...getTypeStyle(i.type), width: 'fit-content', gap: 2 }} className='table-item__payment-type' >
                            <img style={{width: 15}} src={getTypeIcon(i.type)} alt="icon" />
                            {i.time}
                        </div>
                    )
                    }
                </div>
            }
        </>
    );
};

export default GameTypeStatus;