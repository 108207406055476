import { Dropdown, Table } from "antd"
import styled from "styled-components"

export const StyledDropdown = styled(Dropdown)`
  li span {
    font-family: 'Girloy' !important;
    font-size: 20px !important;
  }
`

export const StyledBookingTable = styled(Table)`
  /* margin: 20px; */

  .emptyTable {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    /* background-color: #ffffff; */
  }

  .ant-table.ant-table-bordered > .ant-table-container{
    border: none;
  }

  .ant-table-thead > tr > th {
      padding: 10px 0px 10px 6px !important;
      height: 40px !important;
      background: rgba(255, 255, 255, 1);
  }
  .ant-table-tbody > tr > td {
      /* background: rgba(237, 237, 237, 1); */
      background: rgba(255, 255, 255, 1);
      /* padding: 0 6px; */
      padding: 0;
      height: 40px !important;
      font-family: 'Roboto Mono' !important;
      font-size: 14px;
      font-weight: 500;
  }

  .ant-table-tbody > tr > td:not(.greenColumn) {
      border-color: #F5F5F5 !important;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: rgba(237, 237, 237, 1);
  }

  .tdWithText {
    display: flex;
    align-items: center !important;
    padding: 6px !important;
    width: 100%;
    height: 100%;
    background: rgba(237, 237, 237, 1) !important;
  }
  .tdWithoutText {
    display: flex;
    align-items: center !important;
    width: 100%;
    height: 100%;
    /* margin: auto;
    padding: 6px 5px 0 5px !important; */
    background: rgba(255, 255, 255, 1) !important;
    padding: 6px !important;
  }

  .greenColumn {
    background-color: rgba(104, 184, 126, 0.2) !important;
    border-color: rgba(104, 184, 126, 0.1) !important;
    padding: 0 6px !important;
  }

  .centerColumn {
    text-align: center !important;
    padding: 0 6px !important;
    background: rgba(237, 237, 237, 1) !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
    height: 100%;
  }

  .blueCell {
    background-color: rgba(61, 131, 237, 1);
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .blueHover:hover {
    background-color: rgba(28, 28, 26, 1);
  }

  .greenColTitle {
    padding-left: 22px;
    position: relative;
  }

  .greenColTitle::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(100, 180, 120, 1);
    left: 5px;
    top: 6px;
  }
  
  table {
      /* border-radius: 30px !important; */
    /* border-spacing: 3px !important; */
    max-width: 100% !important;
    background-color: #F5F6F8;
    border: none !important;
    width: 100%;

    .ant-table-cell:nth-child(2){
      padding: 0;
    }

    .ant-table-cell-ellipsis{
      font-family: 'Gilroy' !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
   }

   th{
    box-shadow: none;
    font-family: Gilroy !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    /* line-height: 22px; */
    letter-spacing: 0em;
    text-align: left;
    color: rgba(28, 28, 26, 1);

    overflow-wrap: normal;


    /* &:first-child{
      border-radius: 15px 0 0 0 !important;
    } */
    /* &:last-child{
      border-radius: 0 15px 0 0;
      width: 60px;
      border-top-right-radius: 15px !important;
    } */
    
   }

   td {
    text-align: start !important;
   }

    thead {
      cursor: default;

      /* height: 50px !important; */

      /* background: rgba(220, 222, 230, 1); */
      
      /* th:first-child{
        width: 47px !important;
      }
      th:last-child{
        width: 53px;
      } */
      outline: none;
      border-bottom: none;
    }    
  }

  th, td {
    border: 1px solid rgba(237, 237, 237, 1) !important;
  }

  .goTimeline {
    cursor: pointer;
  }
  .goTimeline:hover {
    background-color: rgba(61, 131, 237, 1) !important;
    border-radius: 40%;
  }

`

export const StyledCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
`