import { Either, IEither } from "../../../../@types/either";
import { Failure } from "../../../../@types/failure";
import { Without } from "../../../../@types/without";
import { TimelineInfo } from "../../../../api/models/OrderForDayResponse";
import { IOrder } from "../../../booking/domain/entities/IOrder";
import { GameType } from "../../../fillial/domain/entities/GameType";
import { CollapsedOrdersView } from "../../ui/components/CollaspsedOrdersView";
import { Dashboard } from "../../ui/components/Dashboard";
import { OrdersTable } from "../../ui/components/OrdersTable";
import { ITimelineSettings } from "../entities/ITimelineSettings";
import { ETimelineType } from "../entities/TimelineType";


export type OrdersPresentationProps = {
    // key: string
    orders: Without<IOrder, Function>[] | null,
    infos: TimelineInfo[] | null,
    isLoading: boolean,
    isSaving: boolean,
    showCanceled: boolean,
    settings: Without<ITimelineSettings, Function>,
    date: moment.Moment,
    edit: (orderStorage: (order: IOrder) => IOrder | null) => void;
    save: () => void;
    cancel: () => void;
    gameTypes: GameType[]
};

export type OrdersView = {
    value: ETimelineType,
    label: string,
    render: React.FC<OrdersPresentationProps>
};

export const orderViewConfigs: OrdersView[] = [
    {

        value: ETimelineType.TABLE,
        render: (props) =>  <CollapsedOrdersView {...props} />,
        // render: (props) => <OrdersTable {...props} />,
        label: 'Брони'
    },
    {
        value: ETimelineType.TIMELINE,
        render: (props) => <Dashboard {...props} />,
        label: 'Версия'
    },
    // {
    //     value: ETimelineType.EXPERIMENT,
    //     render: (props) => <ExperimentTable />,
    //     label: 'Експеримент'
    // }
];