import React, { useEffect, useMemo, useRef, useState } from "react";
import sound from '../assets/sounds/newMessage.mp3';
import { Badge, Button, Col, DatePicker, Drawer, DrawerProps, Form, Layout, message, Modal, notification, Radio, RadioChangeEvent, Row, Space, Spin, Table, Tabs } from 'antd';
// import { toggleOpen } from "../features/booking/redux/slice";
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import OrderCreationForm from "../features/booking/ui/OrderCreateForm";
import moment from "moment";
import { useTitle } from "../common/hooks/useTitle";
import SessionService from "../features/session/domain/sessionService";
import { SessionModal } from "../features/session/ui/sessionModal";
import { GipnoTable } from "../common/presentation/enhasted-table";
import { observer, Observer } from "mobx-react-lite";
import SettingContainer from "../features/timeline/ui/components/SettingsContainer";
import { DeepPartial, FieldName, FormProvider, useForm, useWatch } from "react-hook-form";
import { Without } from "../@types/without";
import { IOrder } from "../features/booking/domain/entities/IOrder";
import { IHookFormOrder } from "../features/booking/domain/entities/IHokFormOrder";
import { TimelineProvider } from "../features/timeline/ui/providers/TimelineConnect";
import { FilialService } from "../features/fillial/domain/services/FilialService";
import { container } from "../di/container";
import { TimelineSettingsViewModel } from "../features/timeline/ui/view-models/timelineSettingsViewModel";
import { OrderingProvider } from "../features/booking/ui/providers/OrderProvider";
import { filterNullable } from "../common/utility-types/helpers/filterNullable";
import { toBookingFromHookForm } from "../features/booking/adapters/hookFormAdapter";
import { TimelinePageViewModel } from "../features/timeline/ui/view-models/timelinePageViewModel";
import { ButtonIconGipno } from "../common/presentation/gipno-ui-kit/button-icon";
import { ButtonGipno } from "../common/presentation/gipno-ui-kit/button";
import { NotificationPlacement } from "antd/lib/notification";

import notif from '../assets/notif.svg';
import NotificationBody from "../features/booking/ui/components/NotificationBody";
import OrderSocketService from "../common/services/OrderSocketService";
import DrawerComp from "../features/booking/ui/components/DrawerComp";
import { OrdersRepository } from "../features/booking/domain/repository/OrdersRepository";
import { ALL_SELECT } from "../@types/baseCollection";
import owo from "../assets/sounds/owo.jpg";
import { orderViewConfigs } from "../features/timeline/domain/configs/ordersViewConfig";
import { RequestStatus } from "../@types/requestStatus";
import { ETimelineType } from "../features/timeline/domain/entities/TimelineType";
import { DATE_FORMAT } from "../common/constants";
import { LoginViewModal } from "../features/authentication/ui/loginViewModal";
import useDebounce, { debounce } from "../common/hooks/useDebounce";
import { useNavigate } from "react-router";
import { AUTH_PATH } from "../common/router/RouterConfiguration";
import { ICity, ILoginResponce } from "../api/models/Login";
import { GameService } from "../api";
import { GameDataSourse } from "../features/fillial/data/GamesDataSourse";
import { filialLocalDataSource } from "../features/fillial/data/db";
import { fromDto } from "../features/fillial/adapters/gameFromDto";
import { TimelineSettings } from "../features/timeline/domain/entities/TimelineSettings";

const { Sider, Content } = Layout;

//Прокидывать к Timeline 
//Отслеживать в компоненте сверху order
//Добавить в 
const TimelinePage: React.FC = observer(() => {

    const filial = container.get(FilialService);
    const lastOrders = container.get(OrdersRepository);

    // const settingsViewModel = useMemo(() => new TimelineSettingsViewModel(), []);


    const city = filial.getSelectedCity;
    // const timelinePresenter =  useMemo(() => new TimelinePageViewModel(), []); //useSelector(state => state.timelineStore);

    const timelinePresenter = container.get(TimelinePageViewModel);

    const [api, contextHolder] = notification.useNotification();

    const [messageApi, contextHolderMess] = message.useMessage();

    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Ошибка сохранения заказа',
            duration: 5,
        });


    };

    const [order, setOrder] = useState<IOrder>();

    const audioNotif = new Audio(sound);

    const openNotification = (order: IOrder, placement: NotificationPlacement = "topLeft") => {
        setOrder(order);
        audioNotif.play();

        api.info({
            message: `Забронена игра на ${order.bookings.find(booking => booking.bookingDate)?.bookingDate?.format('DD.MM.YYYY, HH:mm')}`,
            icon: <img src={notif} alt="!!!" style={{ marginTop: '-5px' }} />,
            description: (
                <>
                    <NotificationBody order={order} key={order.id} />
                    <img src={owo} alt="owo" width={300} />
                </>
            ),
            placement,
            style: { borderRadius: '15px' },
            duration: 20000
        });
    };

    useEffect(() => {
        onChangeDate(timelinePresenter.date);
        timelinePresenter.init();
        audioNotif.load();
        timelinePresenter.startListenClientOrderUpdate(
            (order => openNotification(order)));
        lastOrders.loadClientOrders();
        () => timelinePresenter.closeSocket();
    }, []);

    const auth = new LoginViewModal();

    useTitle({ title: city?.name });

    const navigate = useNavigate();

    useEffect(() => {
        SessionService.init(() => {
            SessionService.pause();
            SessionModal.open({
                onOk: SessionService.start,
                onTimeExpire: () => {
                    SessionModal.close();
                    //dispatch(logout())
                    auth.logout();
                    navigate(AUTH_PATH);
                }
            });
        });


        return () => {
            SessionService.dispose();
            // timelinePresenter.dispose();
        };
    }, []);


    const ordersUnreaded = container.get(OrdersRepository).ordersByClient;
    // const ordersUnreaded = container.get(OrdersRepository).getSortedList();

    const onToggleOpen = () => {
        timelinePresenter.setIsOpenSider(!timelinePresenter.isSiderOpen);
        // timelinePresenter.isSiderOpen ? playMusic2() : stop()
    };

    const formApi = useForm<Without<IHookFormOrder, Function>>({
        mode: 'onChange',
        defaultValues: {
            client: null,
            bookings: [],
            paymentInfo: null,
            isDefault: true,
            vipRoomBookings: []
        }
    });

    const onChangeDate = (date: moment.Moment | null) => {
        timelinePresenter.onSwitchDate(date ?? moment(), formApi);
        // timelinePresenter.loadBookingByDate((date ?? moment()).format(DATE_FORMAT));
    };

    const onChangePeriod = (date: [moment.Moment | null, moment.Moment | null]) => {
        timelinePresenter.setPeriod(date);
    };

    const onCancel = () => {
        return timelinePresenter.onCancel(formApi.reset);
    };

    const [openDrawer, setOpenDrawer] = useState(false);

    const showDrawer = () => {
        setOpenDrawer(true);
    };

    useEffect(() => {
        const subscription = formApi.watch((order: IHookFormOrder, updateInfo: { values: DeepPartial<IHookFormOrder>, name?: FieldName<IHookFormOrder> }) => {
            const uHookFormOrder = order;
            if (uHookFormOrder.isDefault) timelinePresenter.onUpdateOrder(null);
            else timelinePresenter.onUpdateOrder({
                bookings: uHookFormOrder.bookings && filterNullable(uHookFormOrder.bookings.map(booking => toBookingFromHookForm(booking, timelinePresenter.actualOrder?.id ?? ''))),
                client: uHookFormOrder.client && order.client,
                status: uHookFormOrder.status,
                isSendMessage: uHookFormOrder.isSendMessage,
                paymentInfo: uHookFormOrder.paymentInfo,
                comment: uHookFormOrder.comment,
                title: uHookFormOrder.title,
                personalCode: uHookFormOrder.personalCode,
                vipRoomBookings: uHookFormOrder.vipRoomBookings
            });
        }) as { unsubscribe: () => void };
        return () => subscription.unsubscribe();
    }, []);


    const onSubmit = async (values: IHookFormOrder) => {
        await timelinePresenter.onSaveOrder(() => formApi.reset());
        

        if (timelinePresenter.orderStatus === RequestStatus.ERROR) {
            error();
        } else {
            // Обновление компонента Dashboard
            timelinePresenter.loadBookingByDate(timelinePresenter.date.format(DATE_FORMAT));
        }
    };

    useEffect(() => {
        console.log(timelinePresenter.orders);

    }, [timelinePresenter.orders]);

    // const page = localStorage.getItem('settings');



    const { data, isEmpty, debounceFetcher } = useDebounce<ILoginResponce>({
        //@ts-ignore
        fetchCallback: () => auth.checkAuth().catch(() => {
            console.log('NEED TO LOGOUT');

            navigate(AUTH_PATH);
        }), timeout: 1500
    });

    const gameCollection = container.get(GameDataSourse);

    const fetchGames = async (city: ICity | null) => {
        if (city) {
            // await gameCollection.load(city!.gameServerUserId).then(res => {
            await GameService.getGames(city!.gameServerUserId).then(res => {
                const games = res.map(game => fromDto(game));
                filialLocalDataSource.addGames(games);
                gameCollection.addMany(games);
            });
        }
    };


    useEffect(() => {
        const settings = JSON.parse(localStorage.getItem('settings') ?? '{}');
        // const settings = localStorage.getItem('settings');
        const page = settings?.page;

        if (page) {

            // timelinePresenter.changeSelectedView(page as unknown as ETimelineType);
            timelinePresenter.changeSelectedView(page === '0' ? ETimelineType.TABLE : ETimelineType.TIMELINE);

        } else {
            localStorage.setItem('settings', JSON.stringify({ ...settings, page: '0' }));
        }

        setInterval(() => {
            debounceFetcher();
        }, 60000 * 30);

        const city = filial.selectedCity;

        fetchGames(city);


        // debounce
    }, []);

    return (
        <TimelineProvider value={{
            orders: timelinePresenter.orders ?? [],
            params: timelinePresenter.params
        }}>
            {contextHolderMess}
            <OrderingProvider value={{
                order: timelinePresenter.actualOrder,
                orderFormApi: formApi,
                edit: timelinePresenter.buildEdit(formApi)
            }}>
                <Layout hasSider={timelinePresenter.selectedTimelineType == ETimelineType.TIMELINE} style={{background: ' #fefefe'}}>
                    <Layout>
                        <Content style={{ padding: '0px 10px 10px', display: 'flex', flexDirection: 'column', height: '100%', overflow: 'visible' }}>
                            <SettingContainer
                                selectedView={timelinePresenter.selectedTimelineType}
                                timelineSettings={timelinePresenter.timelineSettings}
                                date={timelinePresenter.date}
                                onChangeDate={onChangeDate}
                                onChangePeriod={onChangePeriod}
                                resetPeriod={timelinePresenter.resetPeriod}
                                onChangeView={timelinePresenter.changeSelectedView}
                                packageSelect={<div></div>}
                                drawerHandler={
                                    <Badge count={ordersUnreaded.length} >
                                        <ButtonIconGipno name={'notifications'} onClick={showDrawer}> </ButtonIconGipno>
                                    </Badge>}
                            />
                            {orderViewConfigs
                                .find(v => v.value == timelinePresenter.selectedTimelineType)
                                ?.render({
                                    orders: timelinePresenter.orders ?? null,
                                    infos: timelinePresenter.params,
                                    isLoading: timelinePresenter.loadTimelineStatus == RequestStatus.LOADING,
                                    settings: timelinePresenter.timelineSettings,
                                    edit: timelinePresenter.buildEdit(formApi),
                                    date: timelinePresenter.date,
                                    isSaving: timelinePresenter.isSaving,
                                    save: formApi.handleSubmit(onSubmit),
                                    cancel: onCancel,
                                    showCanceled: timelinePresenter.timelineSettings.showCanceled,
                                    gameTypes: []
                                }) ?? <></>}
                            {contextHolder}
                            <DrawerComp setOpen={setOpenDrawer} isOpened={openDrawer} order={order} ordersUnreaded={ordersUnreaded} />
                        </Content>
                    </Layout>
                    {timelinePresenter.selectedTimelineType == ETimelineType.TIMELINE
                        ? <Sider
                            theme='light'
                            color='transparent'
                            collapsed={!timelinePresenter.isSiderOpen}
                            collapsedWidth={50}
                            width={750}
                        >
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Button style={{
                                    border: 'none'
                                }}
                                    onClick={onToggleOpen}>
                                    {timelinePresenter.isSiderOpen
                                        ? <MenuUnfoldOutlined />
                                        : <MenuFoldOutlined />
                                    }
                                </Button>
                                <div style={{
                                    width: '700px',
                                    overflowY: 'auto',
                                    scrollbarWidth: 'none',
                                    height: '100vh',
                                    position: 'sticky',
                                    top: 0,
                                    right: 0,
                                }}>
                                    <OrderCreationForm
                                        infos={timelinePresenter.params}
                                        order={timelinePresenter.actualOrder}
                                        onCancel={onCancel}
                                        onSave={formApi.handleSubmit(onSubmit)}
                                        isSaving={timelinePresenter.isSaving}
                                    />
                                </div>

                            </div>
                        </Sider>
                        : null}
                </Layout>
            </OrderingProvider>
        </TimelineProvider >

    );
});


export default TimelinePage;
