import React, { useMemo } from 'react';

import vipIcon from '../../../../assets/vip.svg'
import styled from 'styled-components';

const VipsTableView: React.FC<{ vip: number[] }> = ({ vip }) => {


    return (
        <div style={{ display: 'flex', gap: '1.6px' }}>
            {
                vip.map((v, index) =>
                    <StyledVip key={v+index}>
                        <div>
                            <img src={vipIcon} alt="icon" />
                            {v}
                        </div>
                    </StyledVip>
                )}
        </div >
    );
};

export default VipsTableView;

const StyledVip = styled.div`
    width: fit-content;
    border-radius: 16px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 600;
    font-size: 13px;
    height: 28px;
    color: #fff;
    text-align: start;
    background: rgba(28, 28, 26, 1);

    display: flex;
    align-items: center;

    img {
        width: 14px;
        height: 14px;
        padding-bottom: 2px;
        margin-right: 4px;
    }
`