import moment from "moment";
import { OrdersPresentationProps } from "../../domain/configs/ordersViewConfig";
import { Collapse, CollapseProps, Space } from "antd";
import { text } from "stream/consumers";
import { OrdersTable } from "./OrdersTable";
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CollapsedOrdersTableViewModel } from "../view-models/collapsedOrdersTableViewModel";
import { RequestStatus } from "../../../../@types/requestStatus";
import { Observer, observer } from "mobx-react-lite";
import { TasksTable } from "../../../tasks/ui/components/TasksTable";
import { SmartTasksTable } from "../../../tasks/ui/components/SmartTasksTable";
import { container } from "../../../../di/container";
import { OrdersRepository } from "../../../booking/domain/repository/OrdersRepository";
import styled from "styled-components";
import { DATE_FORMAT } from "../../../../common/constants";
import useDebounce from "../../../../common/hooks/useDebounce";
import { ClientData, Order } from "../../../../api";
import { IOrderResponse } from "../../../booking/domain/entities/IOrderResponse";
import { OrderService } from "../../../booking/domain/services/OrderService";
import { GameTypesDataSourse } from "../../../fillial/data/GameTypesDataSourse";
import { ALL_SELECT } from "../../../../@types/baseCollection";
import 'moment/locale/ru'; // Подключаем локаль для русского языка
import { TimelinePageViewModel } from "../view-models/timelinePageViewModel";

moment.locale('ru');

const { Panel } = Collapse;

interface OrderSums {
    [key: string]: number;
}

export const CollapsedOrdersView: React.FC<OrdersPresentationProps> = observer((props) => {

    const gameTypesCollection = container.get(GameTypesDataSourse);
    const types = gameTypesCollection.where(ALL_SELECT);

    const viewModel = useMemo(() => new CollapsedOrdersTableViewModel(), []);

    const orderRepo = container.get(OrdersRepository);
    const orderService = container.get(OrderService);
    const timelinePresenter = container.get(TimelinePageViewModel);

    useEffect(() => {
        viewModel.updateFocusedDate(props.date);
    }, [props.date]);

    useEffect(() => {
        viewModel.updateMap(props.date, {
            getOrdersStatus: props.isLoading ? RequestStatus.LOADING : RequestStatus.SUCCESSFULL,
            orders: props.orders ?? []
        });
    }, [props.orders]);

    // const onOpenDateCollapse = (dates: string[] | string) => {
    //     console.log(dates);

    //     if (Array.isArray(dates)) viewModel.onOpenDate(moment(dates[0]));
    //     else viewModel.onOpenDate(moment(dates));
    // };

    const [allOrders, setAllOrders] = useState<IOrderResponse[]>([]);

    const [orderSums, setOrderSums] = useState<OrderSums>({});
    const [canceledSums, setCanceledSums] = useState<OrderSums>({});

    const [ordersByDay, setOrdersByDay] = useState<Record<string, IOrderResponse[]>>({});


    const [actualMonth, setActualMonth] = useState<string>();



    const calculateOrderSums = (orders: IOrderResponse[]) => {
        const sums: OrderSums = {};
        const canceledSums: OrderSums = {};

        moment.months()
            .map((monthStr) => moment().month(monthStr).format('MM-YYYY'))
            .filter((date) => moment().diff(moment(date, 'MM-YYYY'), 'month') < 2)
            .forEach((month) => {
                Array.from({ length: moment(month, 'MM-YYYY').daysInMonth() }, (_, i) =>
                    moment(month, 'MM-YYYY').startOf('month').add(i, 'days')
                ).forEach((date) => {

                    const ordersPriceSum = orders.reduce((sum, order) => {
                        if (order.status == 'Rejected') return sum;

                        const bookingDate = order.bookings[0]?.bookingDate;
                        const bookDay = moment(moment(bookingDate).format());
                        const dateList = moment(moment(date).format());

                        if (bookingDate && dateList.isSame(bookDay, 'day')) {

                            return sum + (eval(order.paymentInfo.formula) ?? 0);
                        }
                        return sum;
                    }, 0);

                    const canceledOrdersSum = orders.reduce((sum, order) => {
                        if (order.status != 'Rejected') return sum;
                        const bookingDate = order.bookings[0]?.bookingDate;
                        const bookDay = moment(bookingDate);
                        const dateList = moment(date);

                        if (bookingDate && dateList.isSame(bookDay, 'day')) {
                            return sum + (eval(order.paymentInfo.formula) ?? 0);
                        }
                        return sum;
                    }, 0);

                    sums[date.format('DD-MM-YYYY')] = ordersPriceSum;
                    canceledSums[date.format('DD-MM-YYYY')] = canceledOrdersSum;
                });
            });

        setOrderSums(sums);
        setCanceledSums(canceledSums);
    };

    const fetchOrders = async (month: string, period?: [moment.Moment | null, moment.Moment | null]) => {
        let startDate, endDate;
        if (period) {
            startDate = period[0] ? period[0].startOf('day').format(DATE_FORMAT) : moment().startOf('month').format(DATE_FORMAT);
            endDate = period[1] ? period[1].endOf('day').format(DATE_FORMAT) : moment().endOf('month').format(DATE_FORMAT);
        } else {
            startDate = moment(month, 'MM-YYYY').startOf('month').format(DATE_FORMAT);
            endDate = moment(month, 'MM-YYYY').endOf('month').format(DATE_FORMAT);
        }
        console.log(startDate, endDate);


        await orderRepo.getOrdersByPeriod(startDate, endDate).then(() => {
            setAllOrders(orderRepo.ordersPeriod);

            const groupedOrders: Record<string, IOrderResponse[]> = {};
            orderRepo.ordersPeriod.forEach((order) => {
                const bookingDate = moment(order.bookings[0]?.bookingDate).format('DD-MM-YYYY');
                if (!groupedOrders[bookingDate]) groupedOrders[bookingDate] = [];
                groupedOrders[bookingDate].push(order);
            });

            setOrdersByDay(groupedOrders);

            calculateOrderSums(orderRepo.ordersPeriod);
        });
        // setVisibleMonths([startDate, endDate]);
    };

    useEffect(() => {
        const allDatesWithOrders = Object.keys(ordersByDay);
        allDatesWithOrders.forEach(dateString => {
            const dateMoment = moment(dateString, 'DD-MM-YYYY');

            let ordByDate = ordersByDay[dateString]

            const orders = ordByDate.map(o => {
                return {
                    ...o,
                    personalCode: o.personal.id ?? null,
                    client: {
                        id: o.client?.id,
                        name: o.client?.name,
                        phone: o.client?.phone
                    } as ClientData
                }
            })
            viewModel.onOpenDay(dateMoment, orders);
        });
    }, [ordersByDay]);

    const onOpenMonth = (value: string | string[]) => {
        if (value[value.length - 1]) {
            fetchOrders(value[value.length - 1]);
            setActualMonth(value[value.length - 1]);
        }
    };

    const prevOrdersPeriod = useRef(orderRepo.ordersPeriod);
    const ordersPeriod = orderRepo.ordersPeriod;

    const arraysEqual = useCallback((arr1: IOrderResponse[], arr2: IOrderResponse[]) => {
        if (arr1.length !== arr2.length) return false;
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i].id !== arr2[i].id) return false;
            if (arr1[i].id === arr2[i].id && arr1[i].paymentInfo.formula !== arr2[i].paymentInfo.formula) return false;
        }
        return true;
    }, []);

    useEffect(() => {
        if (!arraysEqual(ordersPeriod, prevOrdersPeriod.current)) {
            prevOrdersPeriod.current = ordersPeriod;
            if (actualMonth) {
                fetchOrders(actualMonth);
            }
        }
    }, [orderRepo.ordersPeriod]);

    useEffect(() => {
        const month = moment(orderService.linkToOrder?.bookings[0].bookingDate).format('MM-YYYY');
        onOpenMonth([`${month}`]);
    }, [orderService.linkToOrder]);


    const getMonthInGenitive = (date: moment.Moment): string => {
        const months = {
            0: 'января',
            1: 'февраля',
            2: 'марта',
            3: 'апреля',
            4: 'мая',
            5: 'июня',
            6: 'июля',
            7: 'августа',
            8: 'сентября',
            9: 'октября',
            10: 'ноября',
            11: 'декабря',
        };
        const m = date.month()
        return months[date.month() as keyof typeof months];
    };

    const [visibleMonths, setVisibleMonths] = useState<string[]>([moment().format('MM-YYYY')]);
    const loadNextMonth = useCallback(() => {
        const lastMonth = moment(visibleMonths[visibleMonths.length - 1], 'MM-YYYY');
        const nextMonth = lastMonth.add(1, 'month').format('MM-YYYY');
        setVisibleMonths((prev) => [...prev, nextMonth]);
        fetchOrders(nextMonth);
    }, [visibleMonths]);
    const bottomRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    loadNextMonth();
                }
            },
            { rootMargin: '30px' }
        );

        if (bottomRef.current) {
            observer.observe(bottomRef.current);
        }

        return () => {
            if (bottomRef.current) {
                observer.unobserve(bottomRef.current);
            }
        };
    }, [loadNextMonth]);


    const getDaysInRange = (start: moment.Moment, end: moment.Moment): string[] => {
        const days: string[] = [];
        let current = moment(start).startOf('day');

        while (current.isBefore(end, 'day') || current.isSame(end, 'day')) {
            days.push(current.format('DD-MM-YYYY'));
            current.add(1, 'day');
        }

        return days;
    };

    const [visibleDays, setVisibleDays] = useState<string[]>([]);

    useEffect(() => {
        if (timelinePresenter.period[0] && timelinePresenter.period[1]) {
            const days = getDaysInRange(timelinePresenter.period[0], timelinePresenter.period[1]);
            setVisibleDays(days);
        }
        fetchOrders('', timelinePresenter.period);
        if (timelinePresenter.period[0] === null && timelinePresenter.period[1] === null) setVisibleDays([])
    }, [timelinePresenter.period]);

    // useEffect(() => {
    //         console.log('reset');

    //         setVisibleDays([]);
    //         console.log(visibleMonths);

    // }, [timelinePresenter.resetPeriod])

    return (
        <>
            {visibleDays.map((day) => {
                return (
                    <div key={day}>
                        <StyledHeaderAccord day={'weekday'}>
                            <div className="dateHeader">
                                {`[${moment(day, 'DD-MM-YYYY').format('D')} ${getMonthInGenitive(moment(day, 'DD-MM-YYYY'))} — ${moment(day, 'DD-MM-YYYY').format('dddd').toLocaleLowerCase()}]`}
                            </div>
                            <div className="lineHeader"><div /></div>
                            <div className="sumHeader">
                                {canceledSums[day] > 0 && (
                                    <span style={{ color: '##E74646', marginRight: 4 }}>
                                        {canceledSums[day]} ₽
                                    </span>
                                )}
                                {orderSums[day] > 0 && <span>{`${orderSums[day]} ₽`}</span>}
                            </div>
                        </StyledHeaderAccord>

                        <OrdersTable
                            {...props}
                            key={day}
                            date={moment(day, 'DD-MM-YYYY')}
                            isLoading={viewModel.ordersDateMap.get(day.replaceAll('-', ':'))?.getOrdersStatus === RequestStatus.LOADING}
                            orders={viewModel.ordersDateMap.get(day.replaceAll('-', ':'))?.orders ?? []}
                            gameTypes={types}
                        />
                    </div>
                )
            })}



            {visibleDays.length == 0 && visibleMonths.map((month) => (
                <div key={month}>
                    {Array.from(
                        { length: moment(month, 'MM-YYYY').daysInMonth() },
                        (_, i) => moment(month, 'MM-YYYY').startOf('month').add(i, 'days')
                    ).filter(date => date.isSameOrAfter(moment(), 'day'))
                        .map((date) => {
                            const dayType = date.weekday() === 5
                                ? 'saturday'
                                : date.weekday() === 6
                                    ? 'sunday'
                                    : 'weekday';
                            return (
                                <div key={date.format('DD-MM-YYYY')}>
                                    <StyledHeaderAccord day={dayType}>
                                        {/* <div>{date.format('DD-MM dddd')}</div> */}
                                        <div className="dateHeader">{`[${date.format('D')} ${getMonthInGenitive(date)} — ${date.format('dddd').toLocaleLowerCase()}]`}</div>
                                        <div className="lineHeader"><div /></div>
                                        <div className="sumHeader">
                                            {canceledSums[date.format('DD-MM-YYYY')] > 0 && (
                                                <span style={{ color: '##E74646', marginRight: 4 }}>
                                                    {canceledSums[date.format('DD-MM-YYYY')]} ₽
                                                </span>
                                            )}
                                            {orderSums[date.format('DD-MM-YYYY')] > 0 &&
                                                <span>{`${orderSums[date.format('DD-MM-YYYY')]} ₽`}</span>
                                            }
                                        </div>
                                    </StyledHeaderAccord>
                                    {/* <Space size={20} direction="vertical"> */}
                                    <OrdersTable
                                        {...props}
                                        key={date.format(moment.defaultFormat)}
                                        date={date}
                                        isLoading={
                                            viewModel.ordersDateMap.get(date.format(viewModel.dateFormat))
                                                ?.getOrdersStatus === RequestStatus.LOADING
                                        }
                                        orders={
                                            viewModel.ordersDateMap.get(date.format(viewModel.dateFormat))
                                                ?.orders ?? []
                                        }
                                        gameTypes={types}
                                    />
                                    {/* </Space> */}
                                </div>
                            )
                        })}
                </div>
            ))
            }
            
            {visibleDays.length == 0 &&<div ref={bottomRef} style={{ height: '1px' }} />}
        </>
    );
});

const StyledHeaderAccord = styled.div<{ day: "saturday" | "sunday" | "weekday" }>`
    height: 33px;
    width: 100%;
    background-color: ${({ day }) =>
        day === 'saturday' ? 'rgba(151, 71, 255, 0.05)' :
            day === 'sunday' ? 'rgba(238, 110, 126, 0.05)' :
                'rgba(28, 28, 26, 0.05)'};
    padding: 4px;
    margin: 10px 0;

    font-family: 'Gilroy';
    font-size: 16px;
    font-weight: 600;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    .dateHeader {
        /* width: fit-content; */
        color: ${({ day }) =>
        day === 'saturday' ? 'rgba(151, 71, 255, 1)' :
            day === 'sunday' ? 'rgba(238, 110, 126, 1)' :
                'rgba(28, 28, 26, 1)'};;
    }

    
    .lineHeader {
        /* min-width: 70%; */
        /* max-width: 80%; */
        /* width: auto; */
        flex-grow: 1;
        height: 2px !important;
        box-sizing: border-box;
        display: block;
    }

    .lineHeader div {
        width: 100%;
        height: 100% !important;
        background-color: ${({ day }) =>
        day === 'saturday' ? 'rgba(151, 71, 255, 1)' :
            day === 'sunday' ? 'rgba(238, 110, 126, 1)' :
                'rgba(28, 28, 26, 1)'};;
    }
    .sumHeader span {
        padding: 3px 6px;
        color: #FFFFFF;
        background-color: #64B478;
        font-size: 16px;
        height: 25px;
    }
`;