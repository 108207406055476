import { Button, Checkbox, Col, Input, Modal, Popconfirm, Popover, Row, Select, Space, message } from 'antd';
import React, { Fragment, useCallback, useContext, useMemo, useRef, useState } from 'react';
import BookingPopover from '../../../booking/ui/BookingPopover';
import ConfirmStatusTag from './ConfirmStatusTag';
import GUEST_ICON from '../../../../assets/guest.svg';
import { CellContentType } from '../../domain/entities/IBookingCell';
import { IBooking } from '../../../booking/domain/entities/IBooking';
import ColorPool from '../../../../common/utility-types/utils/ColorPool';
import { TimelineConnect } from '../providers/TimelineConnect';
import { SmartGuestCountController } from '../../../booking/ui/components/SmartGuestCountController';
import { ConnectOrder } from '../../../booking/ui/providers/OrderProvider';
import { IOrder } from '../../../booking/domain/entities/IOrder';
import { EOrderStatus } from '../../../booking/domain/entities/EOrderStatus';
import { SmartGameController } from '../../../booking/ui/components/SmartGameController';
import { SmartGameTypesController } from '../../../booking/ui/components/SmartGameTypeController';
import useContextMenu, { BookingContext } from '../../../booking/domain/helpers/ContextMenu';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import glasses from '../../../../assets/Glasses.svg';
import { title } from 'process';
import { TableViewModel } from '../view-models/tableViewModel';
import { container } from '../../../../di/container';
import { TimelinePageViewModel } from '../view-models/timelinePageViewModel';
import { observer } from 'mobx-react-lite';
import { OrderService } from '../../../booking/domain/services/OrderService';
import { IOrderSocket, OrdersRepository } from '../../../booking/domain/repository/OrdersRepository';
import { DashboardViewModel } from '../view-models/dashboardViewModel';
import { VipRoomBookingsDto } from '../../../../api/models/VipRoomBookings';

import { ReactComponent as LoungeIcon } from '../../../../assets/icons/lounge_icon.svg';
import { ETimelineType } from '../../domain/entities/TimelineType';

/*
Ячейки разных видос с одним и тем же поведением
2 состояния: редактирование и обычное
У всех ячеек редактирование - одинаковое
передавать определенный pivot, возможно заменить все на OrderView
*/

interface BookedCellProps {
    booking: IBooking,
    isEditing: boolean,
    cellStyle: CellContentType,
    color: string,
    orderTitle: string | null,
    vipRoomBooking: VipRoomBookingsDto[]
}

const getIndexOfBooking = (order: IOrder | null, bookingId: number | string) => order?.bookings.findIndex(b => b.id == bookingId) ?? -1;

const optionsProvider = (order: IOrder | null, setOrder: (order: IOrder | null) => void, booking: IBooking, setPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>): MenuOptions[] => {

    const orderService = container.get(OrderService);

    const dashboardVM = container.get(DashboardViewModel);

    const TimelinePageVM = container.get(TimelinePageViewModel);

    const select = () => {

        if (order) {

            console.log(order.bookings.find(book => book.id === booking.id));

            dashboardVM.setSelectedBookingId(order.bookings.find(book => book.id === booking.id) ?? booking);

        }
    };

    const selectOrder = () => {

        if (order) {

            const bookingsId = order.bookings.map(book => book.id);

            // dashboardVM.setSelectedAllBooking(bookingsId);
            dashboardVM.setSelectedAllBooking(order.bookings);

        };
    };

    const update = () => {
        setOrder(order);
        TimelinePageVM.setIsOpenSider(true);
        if (order) {
            TimelinePageVM.setEditableOrder(order);
        }
    };

    const seeMore = () => {
        setPopoverVisible(true);
    };

    const toTable = () => {
        TimelinePageVM.changeSelectedView(ETimelineType.TABLE);
        orderService.setLinkToOrder(order);
    };

    return [
        {
            optionName: 'Выделить',
            optionFunc: select
        },
        {
            optionName: 'Выделить заказ',
            optionFunc: selectOrder
        },
        {
            optionName: 'Редактировать',
            optionFunc: update
        },
        {
            optionName: 'Подробнее',
            optionFunc: seeMore
        },
        {
            optionName: 'В таблицу',
            optionFunc: toTable
        }
    ];
};



//Контейнер, отображающий все брони в текущей ячейке
const BookedCell: React.FC<BookedCellProps> = ({ booking, color, cellStyle, isEditing, orderTitle, vipRoomBooking }) => {
    const [isPopoverVisible, setPopoverVisible] = useState(false);

    // console.log(vipRoomBooking.vipRoomId);


    const cellViewFactory = useMemo(() => {
        if (isEditing)
            return CreationBookingView;
        switch (cellStyle) {
            case 'simplified':
                return SimplifiedBookingView;
            case 'simplified-spaced':
                return SpacedBookingView;
            default:
                return DefaultBookingView;
        }
    }, [cellStyle, isEditing]);

    const contextMenu = useContext(BookingContext);
    const { isShown, menu, hideContextMenu, showContextMenu } = contextMenu;

    // console.log(booking.orderId);

    return (
        <>
            {!isEditing
                ? <BookingPopover
                    orderId={booking.orderId ?? ''}
                    color={color}
                    isPopoverVisible={isPopoverVisible}
                    setPopoverVisible={setPopoverVisible}
                >
                    {cellViewFactory({
                        orderId: booking.orderId ?? '',
                        color: color,
                        booking: booking,
                        title: orderTitle ?? undefined,
                        vipRoomBooking: vipRoomBooking,
                        setPopoverVisible,

                        menuSettings: {
                            // menuOptions: optionsProvider(),
                            showMenu: showContextMenu,
                            onClick: hideContextMenu,
                            isShown: isShown,
                            menu: menu
                        }
                    })}
                </BookingPopover>
                : cellViewFactory({
                    orderId: booking.orderId ?? '',
                    color: color,
                    booking: booking,
                    title: orderTitle ?? undefined,
                    vipRoomBooking: vipRoomBooking,
                    setPopoverVisible: setPopoverVisible,

                    menuSettings: {
                        // menuOptions: optionsProvider(),
                        showMenu: showContextMenu,
                        onClick: hideContextMenu,
                        isShown: isShown,
                        menu: menu
                    }
                })
            }
        </>

    );
};

export default BookedCell;


interface BookingViewProps {
    orderId: string,
    color: string,
    borderColor?: string,
    booking: IBooking,
    title?: string
    vipRoomBooking?: VipRoomBookingsDto[]
}

type CreationBookingViewProps = Omit<BookingViewProps, 'booking'> & { booking: IBooking, menuSettings: MenuSettings, setPopoverVisible: React.Dispatch<React.SetStateAction<boolean>> };

interface MenuSettings {
    // menuOptions?: MenuOptions[],
    showMenu: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, options: MenuOptions[]) => void,
    onClick: () => void,
    isShown: boolean,
    menu: JSX.Element
}

interface MenuOptions {
    optionName: string,
    optionFunc: () => void
}

interface BookingHeaderProps {
    id: string,
    color: string,
    title?: string
    guestCount?: number,
    vipRoomIds?: number[]
}

interface BookingBodyProps {
    booking: IBooking
}

export const SimplifiedBookingView: React.FC<CreationBookingViewProps> = ({ orderId, booking, color, title, menuSettings, setPopoverVisible, vipRoomBooking }) => {

    const { showMenu, onClick, isShown, menu } = menuSettings;

    const orderRepo = container.get(OrdersRepository);

    const orderByRepo = orderRepo.getOrderById(orderId) as IOrder;

    const dashboardVM = container.get(DashboardViewModel);

    return (
        <ConnectOrder>
            {({ setOrder }) =>
                <div style={{ height: '100%', display: 'flex' }}
                    onContextMenu={(e) => showMenu(e, optionsProvider(orderByRepo, setOrder, booking, setPopoverVisible))}
                    onClick={onClick}
                    className={dashboardVM.selectedBookings.findIndex(book => book.id === booking.id) > -1 ? 'selectedCell' : ''}
                >
                    {
                        dashboardVM.selectedBookings.includes(booking) &&
                        <Checkbox checked={dashboardVM.selectedBookings.includes(booking)}
                            style={{ position: 'absolute', right: '10px', top: '5px', zIndex: 1 }} />
                    }
                    {/* <div style={dashboardVM.selectedBookings.includes(booking.id) ? { filter: 'grayscale(40%) blur(1px)' } : undefined}> */}
                    <BookedCellHeader vipRoomIds={vipRoomBooking?.map(vip => Number(vip.vipRoomId))} id={orderId} color={color} guestCount={booking.guestCount} title={title} />
                    {/* </div> */}
                    {
                        isShown
                        &&
                        createPortal(menu, document.body, `${Math.random() * 100}`)
                    }
                </div>
            }
        </ConnectOrder>
    );
};

export const DefaultBookingView: React.FC<CreationBookingViewProps> = ({ orderId, booking, color, title, menuSettings, setPopoverVisible, vipRoomBooking }) => {
    const windowInnerWidth = document.documentElement.clientWidth;

    const { showMenu, onClick, isShown, menu } = menuSettings;

    const orderRepo = container.get(OrdersRepository);

    const orderByRepo = orderRepo.getOrderById(orderId) as IOrder;

    const dashboardVM = container.get(DashboardViewModel);

    return (
        // <ConnectOrder>
        //     {({ setOrder }) =>
        //         <div style={{ height: '100%' }}
        //             className={dashboardVM.selectedBookings.includes(booking.id) ? 'selectedCell' : ''}
        //             onContextMenu={(e) => showMenu(e, optionsProvider(orderByRepo, setOrder, booking))}
        //             onClick={onClick}
        //         >
        //             {
        //                 dashboardVM.selectedBookings.includes(booking.id) &&
        //                 <Checkbox checked={dashboardVM.selectedBookings.includes(booking.id)}
        //                     style={{ position: 'absolute', right: '10px', top: '5px', zIndex: 1 }} />
        //             }
        //             {
        //                 windowInnerWidth < 900
        //                     ? <MobileOrder style={{ backgroundColor: color }}>
        //                         <p>Заказ {title ? title : `#${orderId.substring(0, 4)}`}</p>
        //                         <div style={{ color: color }}>
        //                             <img src={glasses} alt="" />
        //                             {booking.guestCount}
        //                         </div>
        //                     </MobileOrder>
        //                     : <div style={dashboardVM.selectedBookings.includes(booking.id) ? { filter: 'grayscale(40%) blur(1px)', margin: '5px' } : undefined}>
        //                         <BookedCellHeader id={orderId} color={color} guestCount={booking.guestCount} title={title} />
        //                         <BookedCellBody booking={booking} />
        //                     </div>
        //             }

        //             {
        //                 isShown
        //                 &&
        //                 createPortal(menu, document.body, `${Math.random() * 100}`)
        //             }
        //         </div>
        //     }
        // </ConnectOrder>
        <ConnectOrder>
            {({ setOrder }) =>
                <div style={{ height: '100%' }}
                    className={dashboardVM.selectedBookings.findIndex(book => book.id === booking.id) > -1 ? 'selectedCell' : ''}
                    onContextMenu={(e) => showMenu(e, optionsProvider(orderByRepo, setOrder, booking, setPopoverVisible))}
                    onClick={onClick}
                >
                    {
                        dashboardVM.selectedBookings.findIndex(book => book.id === booking.id) > -1 &&
                        <Checkbox checked={dashboardVM.selectedBookings.findIndex(book => book.id === booking.id) > -1}
                            style={{ position: 'absolute', right: '10px', top: '5px', zIndex: 1 }} />
                    }
                    {
                        windowInnerWidth < 900
                            ? <MobileOrder style={{ backgroundColor: color }}>
                                <p>Заказ {title ? title : `#${orderId.substring(0, 4)}`}</p>
                                <div style={{ color: color }}>
                                    <img src={glasses} alt="" />
                                    {booking.guestCount}
                                </div>
                            </MobileOrder>
                            : <div style={dashboardVM.selectedBookings.findIndex(book => book.id === booking.id) > -1 ? { filter: 'grayscale(40%) blur(1px)', margin: '5px' } : undefined}>
                                <BookedCellHeader id={orderId} color={color} guestCount={booking.guestCount} title={title} vipRoomIds={vipRoomBooking?.map(vip => Number(vip.vipRoomId))} />
                                <BookedCellBody booking={booking} />
                            </div>
                    }

                    {
                        isShown
                        &&
                        createPortal(menu, document.body, `${Math.random() * 100}`)
                    }
                </div>
            }
        </ConnectOrder>
    );
};

const MobileOrder = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    p{
        font-family: Gilroy;
        font-size: 18px;
        font-weight: 600;
        line-height: 25.2px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
    }
    div{
        background-color: white;
        border-radius: 40px;
        width: 40%;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            mix-blend-mode: luminosity;
            margin-right: 10px;
        }
    }
`;

export const SpacedBookingView: React.FC<CreationBookingViewProps> = ({ orderId, booking, color, title, menuSettings, setPopoverVisible, vipRoomBooking }) => {

    const { showMenu, onClick, isShown, menu } = menuSettings;

    const orderRepo = container.get(OrdersRepository);

    const orderByRepo = orderRepo.getOrderById(orderId) as IOrder;

    const dashboardVM = container.get(DashboardViewModel);

    return (
        <ConnectOrder>
            {({ order, setOrder }) =>
                <div
                    onContextMenu={(e) => showMenu(e, optionsProvider(orderByRepo, setOrder, booking, setPopoverVisible))}
                    onClick={onClick}
                    className={dashboardVM.selectedBookings.findIndex(book => book.id === booking.id) > -1 ? 'selectedCell' : ''}
                >
                    {
                        dashboardVM.selectedBookings.includes(booking) &&
                        <Checkbox checked={dashboardVM.selectedBookings.includes(booking)}
                            style={{ position: 'absolute', right: '10px', top: '5px', zIndex: 1 }} />
                    }
                    <div style={dashboardVM.selectedBookings.includes(booking) ? { filter: 'grayscale(40%) blur(1px)', margin: '5px', height: '128px' } : undefined}>
                        <BookedCellHeader id={orderId} color={color} guestCount={booking.guestCount} title={title} vipRoomIds={vipRoomBooking?.map(vip => Number(vip.vipRoomId))} />
                    </div>
                    <div style={{
                        backgroundColor: 'transparent',
                        flexGrow: 2
                    }} />
                    {
                        isShown
                        &&
                        createPortal(menu, document.body, `${Math.random() * 100}`)
                    }
                </div>
            }
        </ConnectOrder>
    );
};

export const CreationBookingView: React.FC<CreationBookingViewProps> = ({ orderId, booking, color, title, menuSettings, setPopoverVisible, vipRoomBooking }) => {

    // const {showMenu, menuOptions, onClick} = menuSettings;

    const contextMenu = useContext(BookingContext);
    const { isShown, menu, hideContextMenu, showContextMenu } = contextMenu;

    const optionsBuilder = (order: IOrder | null, setOrder: (order: IOrder | null) => void,): MenuOptions[] => {

        const deleteOption = () => {
            if (order) {
                const newOrder: IOrder = {
                    ...order, bookings: order?.bookings.filter(v => v.id !== booking?.id) ?? []
                };
                setOrder(newOrder);
            }
        };
        const { confirm } = Modal;

        const showDeleteConfirm = () => {
            confirm({
                title: 'Вы действительно хотите удалить бронь?',
                icon: null,
                okText: 'Да',
                okType: 'danger',
                cancelText: 'Нет',
                onOk() {
                    deleteOption();
                },
                onCancel() {
                },
            });
        };

        return [
            // {optionName: 'Удалить', optionFunc: deleteOption }
            {
                optionName: 'Удалить', optionFunc: showDeleteConfirm
            },
            ...optionsProvider(order, setOrder, booking, setPopoverVisible)
        ];
    };

    const dashboardVM = container.get(DashboardViewModel);

    const arrColor: Record<number, string> = {
        0: '#d6ad5c',
        1: '#64b478',
        2: '#6c80cd',
        4: '#af76b4',
        5: '#e79664',
        6: '#72d4d4',
        10: '#df5c5c',
        11: '#df5c5c'
    }

    return (
        <ConnectOrder>
            {({ order, setOrder }) => {
                const type = order?.bookings.at(getIndexOfBooking(order, booking.id))?.gameTypeId;
                let color = type;
                if(type == -1 || !type) {
                    color = 0;
                }
                return (
                    <div
                        onContextMenu={(e) => showContextMenu(e, optionsBuilder(order, setOrder))}
                        onClick={hideContextMenu}
                        className={dashboardVM.selectedBookings.findIndex(book => book.id === booking.id) > -1 ? 'selectedCell' : ''}
                    >
                        <BookedCellHeader
                            title={title}
                            id={orderId}
                            color={arrColor[color ?? 0]}
                            // color={ColorPool.instance.getColor(order?.bookings.at(getIndexOfBooking(order, booking.id))?.gameTypeId ?? -1)}
                            guestCount={order?.bookings.at(getIndexOfBooking(order, booking.id))?.guestCount}
                        />
                        {
                            dashboardVM.selectedBookings.includes(booking) &&
                            <Checkbox checked={dashboardVM.selectedBookings.includes(booking)}
                                style={{ position: 'absolute', right: '10px', top: '5px', zIndex: 1 }} />
                        }

                        <div style={dashboardVM.selectedBookings.includes(booking) ? { filter: 'grayscale(40%) blur(1px)' } : undefined}>
                            <BookingCellBody booking={booking} />
                        </div>

                        {
                            isShown
                            &&
                            createPortal(menu, document.body, `${Math.random() * 10}`)
                        }
                    </div>
                )
            }
            }
        </ConnectOrder>
    );
};

const BookedCellBody: React.FC<BookingBodyProps> = ({ booking }) => {

    const orderRepo = container.get(OrdersRepository);
    // const [fullOrderById, setFullOrderById] = useState<IOrderSocket | null>();
    // if (booking.gameTypeId === -1) {
    //     setFullOrderById(orderRepo.getOrderById(booking.orderId));
    // }

    const order = useMemo(() => orderRepo.getOrderById(booking.orderId), [booking.gameTypeId]);

    return (
        <div className='cell__wrapper' >
            <p className='booked-item__title'>
                {
                    booking.game?.title
                        ? booking.game?.title
                        : booking.gameTypeId !== -1 && !booking.game?.title ? 'На месте' : 'Нет игры'
                }
            </p>
            {
                booking.gameTypeId !== -1 ?
                    <ConfirmStatusTag status={order?.status ?? EOrderStatus.CREATED} />
                    : order?.comment &&
                    <div className='cell__description'>
                        <span>
                            Комментарий:
                        </span>
                        {order?.comment}
                    </div>
            }
        </div>
    );
};

const BookingCellBody: React.FC<{ booking: IBooking }> = ({ booking }) => {

    const onInputClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
    };


    return (
        <div className='cell__wrapper' onClick={onInputClick}>
            <Space size={8} direction='vertical'>
                <SmartGuestCountController bookingId={booking.id} />
                <SmartGameTypesController bookingId={booking.id} />
                {/* <SmartGameController bookingId={booking.id} /> */}
            </Space>
        </div>
    );
};

const BookedCellHeader: React.FC<BookingHeaderProps> = ({ id, color, guestCount, title, vipRoomIds }) => {

    const cellTitle = useMemo(() => Boolean(title) ? title : `Заказ №${id.substring(0, 4)}`, [title, id]);

    const stringVips = vipRoomIds ? vipRoomIds.join(', ') : null;

    return (
        <div className='cell__header' style={{ backgroundColor: color }}>
            <div>{cellTitle}</div>
            <div style={{ display: 'flex', gap: '8px' }}>
                {stringVips ?
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px', border: '1px solid #fff', borderRadius: '100px', padding: '3px 8px' }}>
                        <div>{stringVips ?? '?'}</div>
                        <LoungeIcon fill={'#ffffff'} style={{ width: '16px' }} />
                    </div>
                    : null
                }
                {/* {
                    vipRoomIds?.map(id => (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '4px', border: '1px solid #fff', borderRadius: '100px', padding: '3px 8px' }}>
                            <div>{id ?? '?'}</div>
                            <LoungeIcon fill={'#ffffff'} style={{ width: '16px' }} />
                        </div>
                    ))
                } */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <div>{guestCount ?? '?'}</div>
                    <img style={{ height: '17px', width: '17px' }} src={GUEST_ICON} />
                </div>
            </div>
        </div>
    );
};
